import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';

import { HttpClient } from '../../services/http-client.service';
import { AuthService } from '../../services/auth.service';
import { RecorderService } from '../../shared/recorder/recorder.service';
import { environment } from '../../../environments/environment';


@Injectable()
export class STTService {
  title = 'Text Output'
  desc = 'Speech to Text API is a service by Mawdoo3 AI that transfers voice to text.'
  icon = '/assets/img/api-icons/voice-recognition2.png'

  private apiUrl = 'https://api.mawdu.com/voice/' + environment.oldApiVersion;
  private options = {
    headers: new Headers()
  };

  constructor(
    protected http: HttpClient,
    protected auth: AuthService,
    private recorder: RecorderService
  ){ }

  // fetchSampleData(): Promise<any> {
  //   // will be implemented soon
  // }

  getText(audio: Blob, lang: string = 'ar-JO', uid: string = localStorage.getItem('uid'), client: string = 'mawdoo3-demo', sampleRate: number = this.recorder.sampleRate): Promise<any> {
    this.options.headers.set('Content-Type', `audio/wav; rate=${sampleRate}`);
    return this.http.post(`${this.apiUrl}/transcribe?lang=${lang}&uid=${uid}&client=${client}`, audio, this.options)
      .then(rsp => rsp.json())
      .catch(err => Promise.reject(err));
  }

  contribute(audioId, text, uid: string = localStorage.getItem('uid')): any {
    return this.http.post(`${this.apiUrl}/transcribe/contribute?id=${audioId}&text=${text}&uid=${uid}`, null, this.options)
      .then(rsp => rsp.json())
      .catch(err => err);
  }

}
