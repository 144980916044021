import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { HttpClient } from '../../services/http-client.service';
import { AuthService } from '../../services/auth.service';
import { UtilService } from '../../services/util.service';
import { environment } from '../../../environments/environment';
import { AbstractNLPService } from '../AbstractNLPService'

@Injectable({ providedIn: 'root' })
export class NerService extends AbstractNLPService {

  apiURL = 'https://api.mawdu.com/' + environment.newApiVersion + '/ner';
  samplesFile = '/assets/samples/ner.json'
  method = 'post'
  options = { headers: new Headers({ 'content-type': 'application/json' }) }
  resultList = []
  title = 'Entities'
  desc = 'Named-entity recognition API is a serivice by Mawdoo3 AI that seeks to locate and classify named entity mentions in unstructured text into pre-defined categories such as the person names, organizations, locations and quantities.'
  icon = '/assets/img/api-icons/named-entity-recognition.png'
  constructor(protected http: HttpClient,
    protected auth: AuthService,
    protected util: UtilService) {
    super(http, auth, util)
  }

  present(response: any) {
    let result
    try {
      result = response.json().results
    } catch (error) {
      result = response.results
    }
    var end_res = []
    for (var i = 0; i < result["entities"].length; ++i) {
      end_res.push(result["entities"][i]["entity"] + ": " + result["entities"][i]["type_ar"])
    }
    // remove duplicates
    this.resultList = Array.from(new Set(end_res))
    return { resultList: this.resultList }
  }
}
