import { Injectable } from '@angular/core';
import { URLSearchParams } from '@angular/http'
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class UtilService {

  constructor(
    private sanitizer: DomSanitizer,
  ){}

  toURLParams(json): string {
    let params = new URLSearchParams();
    for(let key in json) {
      params.set(key, json[key]);
    }
    return params.toString();
  }

  toCamelCase(str: string): string {
    return str.replace(/_([a-z])/g, function (g) { return g[1].toUpperCase(); });
  }

  toSnakeCase(str: string) {
    return str.replace(/([A-Z])/g, function($1){return "_"+$1.toLowerCase();});
  }

  getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

}
