import { Component, OnInit, Input } from '@angular/core';
import { AlertsService } from '../shared/alerts/alerts.service';
import { User } from '../models/user';

@Component({
  selector: 'salma-page',
  templateUrl: './salma.component.html',
  styleUrls: ['./salma.component.css']
})

export class SalmaComponent implements OnInit {

  @Input() user: User = new User();

  showSpinner: boolean = false;
  userForm: string = 'signup';
  alerts: any[] = [];

  constructor(
    private _alerts: AlertsService
  ){}

  ngOnInit(): void {
    this._alerts.alerts = [];
  }

  setForm(val: string): void {
    this.alerts = [];
    this.userForm = val;
  }

  handleUserCreated(user): void {
    this.user = user;
    this.setForm('signin');
    this._alerts.addAlert({ type: 'success', message: 'You have successfully signed up.' })
  }

}
