import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpModule } from '@angular/http';
import * as $ from 'jquery';

// Import components
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/main-navbar/main-navbar.component';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { AlertsComponent } from './shared/alerts/alerts.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { SalmaComponent } from './salma/salma.component';
import { Nsurl2019Task8Component } from './nsurl-2019-task8/nsurl-2019-task8.component';
import { GharredComponent } from './gharred/gharred.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ApisGridComponent } from './apis/apis-grid.component';
import { SearchDemoComponent } from './apis/search/search-demo.component';
import { AskDemoComponent } from './apis/ask/ask-demo.component';
import { NlpDemoComponent } from './apis/nlp/nlp-demo.component';
import { CorrectnessDemoComponent } from './apis/correctness/correctness-demo.component';
import { SimilarDemoComponent } from './apis/similar/similar-demo.component';
import { AudioRecordingComponent } from './shared/recorder/recorder.component';
import { UserAccountComponent } from './users/account/account.component';
import { SignInPageComponent } from './signin-page/signin-page.component';
import { SignInFormComponent } from './shared/signin-form/signin-form.component';
import { SignUpFormComponent } from './shared/signup-form/signup-form.component';
import { STTContributionComponent } from './shared/stt-contribution/stt-contribution.component';
import { forgotPasswordFormComponent } from './shared/forgot-password-form/forgot-password-form.component';
import { SamplesComponent } from './shared/samples/samples.component';
import { ApiDocComponent } from './api-doc/api-doc.component';
import { PricingComponent } from './pricing/pricing.component';
import { TTSSaudiDemoComponent } from './apis/tts-saudi/tts-saudi-demo.component';

// Import Services
import { AuthService } from './services/auth.service';
import { HttpClient } from './services/http-client.service';
import { UtilService } from './services/util.service';

import { TashkeelService } from './apis/tashkeel/tashkeel.service';
import { CoRefService } from './apis/coref/coref.service';
import { TTSService } from './apis/tts/tts.service';
import { STTService } from './apis/stt/stt.service';
import { SearchService } from './apis/search/search.service';
import { AskService } from './apis/ask/ask.service';
import { TopicService } from './apis/topic/topic.service';
import { PosService } from './apis/pos/pos.service';
import { NormalizeService } from './apis/normalize/normalize.service';
import { NerService } from './apis/ner/ner.service';
import { NlpService } from './apis/nlp/nlp.service';
import { SegmentService } from './apis/morphological-segmentation/segment.service';
import { SimilarService } from './apis/similar/similar.service';
import { CorrectnessService } from './apis/correctness/correctness.service';
import { SentimentsAnalysisService } from './apis/sentiments-analysis/sentiments-analysis.service';
import { QuestionSimilarityService } from './apis/question-similarity/question-similarity.service';
import { RecorderService } from './shared/recorder/recorder.service';
import { AlertsService } from './shared/alerts/alerts.service';
import { UserService } from './users/user.service';
import { DialectService } from './apis/dialect/dialect.service';
import { SpellCheckerService } from './apis/spell-checker/spell-checker.service';
import { AllInOneService } from './apis/all-in-one/all-in-one.service';
import { PresenterComponent } from './apis/presenter/presenter.component';
import { TTSServiceSaudi } from './apis/tts-saudi/tts-saudi.service';
// import guards
import { AuthGuard } from './guards/auth.guard';
import { GuestGuard } from './guards/guest.guard';
import { AppPrivacyPolicyComponent } from './app-privacy-policy/app-privacy-policy.component';

// import pipes
import { EscapeHtmlPipe } from './pipes/keep-html.pipe';
import { AllInOneComponent } from './apis/all-in-one/all-in-one.component';
import { SingleApiComponent } from './apis/single-api/single-api.component';


// import angular material
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  imports: [ // declare modules here
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    MatTabsModule,
    MatSnackBarModule
  ],
  declarations: [ // declare components here
    AppComponent,
    NavbarComponent,
    LoadingSpinnerComponent,
    AlertsComponent,
    LandingPageComponent,
    SalmaComponent,
    Nsurl2019Task8Component,
    GharredComponent,
    AboutUsComponent,
    ContactUsComponent,
    ApisGridComponent,
    SearchDemoComponent,
    AskDemoComponent,
    NlpDemoComponent,
    SimilarDemoComponent,
    CorrectnessDemoComponent,
    AudioRecordingComponent,
    UserAccountComponent,
    SignInPageComponent,
    SignInFormComponent,
    SignUpFormComponent,
    STTContributionComponent,
    forgotPasswordFormComponent,
    SamplesComponent,
    ApiDocComponent,
    PricingComponent,
    AppPrivacyPolicyComponent,
    EscapeHtmlPipe,
    AllInOneComponent,
    SingleApiComponent,
    PresenterComponent,
    TTSSaudiDemoComponent
  ],
  providers: [ // declare services here
    AuthService,
    UtilService,
    AuthGuard,
    GuestGuard,
    TashkeelService,
    CoRefService,
    TTSService,
    STTService,
    SearchService,
    AskService,
    TopicService,
    PosService,
    NormalizeService,
    NerService,
    NlpService,
    SegmentService,
    SimilarService,
    CorrectnessService,
    SentimentsAnalysisService,
    QuestionSimilarityService,
    RecorderService,
    AlertsService,
    UserService,
    HttpClient,
    DialectService,
    SpellCheckerService,
    AllInOneService,
    TTSServiceSaudi
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }