import { Component } from '@angular/core';
import { AlertsService } from './alerts.service';

@Component({
  selector: 'alerts',
  templateUrl: 'alerts.component.html',
  styleUrls: ['./alerts.component.css']
})

export class AlertsComponent {

  constructor(
    public _alerts: AlertsService
  ){}

  closeAlert(i:number) {
    this._alerts.alerts.splice(i, 1);
  }

}
