import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services/auth.service';
import { AlertsService } from '../alerts/alerts.service';
import { User } from '../../models/user';

@Component({
  selector: 'signin-form',
  templateUrl: './signin-form.component.html',
  styleUrls: ['./signin-form.component.css']
})

export class SignInFormComponent {
  showSpinner: boolean = false;
  showForgotPassword: boolean = false;

  @Input() alerts: any[] = [];
  @Input() user: User = new User();

  constructor(
    private auth: AuthService,
    private alert: AlertsService,
    private router: Router
  ) {
    this.alerts = this.alert.localAlerts;
  }

  login(): void {
    this.showSpinner = true;
    this.auth.login(this.user)
      .then((rsp) => {
        this.showSpinner = false;
        let closeBtn = <HTMLElement> document.querySelector("#modal-signin .close");
        closeBtn.click();
        window.location.reload();
      })
      .catch((err) => {
        this.showSpinner = false;
        this.alert.addAlert({ type: 'danger', message: err.message }, false);
      });
  }

  forgotPasswordHandler(): void {
    this.alert.addAlert({ type: 'success', message: 'Your password has been updated successfully.' }, false);
    this.showForgotPassword = false;
  }

  closeAlert(i: number) {
    this.alerts.splice(i, 1);
  }

}
