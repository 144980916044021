import { Component, OnInit, Input } from '@angular/core';
import { SpellCheckerService } from '../spell-checker/spell-checker.service';

/**
 * Presenter for the result of any NLP service. it can present text, list and audio
 *  
 *  @param resultText<string>: plain text result
 *  @param resultList<string[]>: list result
 *  @param resultPlayer<any>: audio result
 *  @param title<string>: title for the result 
 *  @param customText<bool>: present an html element
 *  @param customList<bool>: present a list of html elements 
 */
@Component({
  selector: 'presenter',
  templateUrl: './presenter.component.html',
  styleUrls: ['./presenter.component.css']
})
export class PresenterComponent implements OnInit{
  @Input() resultText: string;
  @Input() resultList;
  @Input() resultBlob;
  @Input() title: string = '';
  @Input() customText: boolean;
  @Input() customList: boolean;
  suggestionsList: String[];
  currentRightClickedEl: any;

  constructor(public spellCheckerService: SpellCheckerService) { }

  ngOnInit() {
    let module = this;
    document.addEventListener("contextmenu", function(e) {
      let closestEl = <any> e.target;
      closestEl = closestEl.closest(".correctable");
      e.preventDefault();
      let suggestions = JSON.parse($(closestEl).attr("data-suggestions")); 
      let container = $("#Presenter").parent().offset();

      let coords = {
        xCoord: e.clientX - container.left,
        yCoord: e.clientY - container.top
      }

      module.showSpellCheckerSuggestions(suggestions,coords);
      module.currentRightClickedEl = e;
      return false;
    },false);
  }

  ngOnChanges(changes: any) {
    
    var audio = document.querySelector("audio")
    if ('resultBlob' in changes && audio != null) {
      audio.load(); 
      audio.play().catch(error => {})

    }
  }

  resetPlayer() {

  }

  showSpellCheckerSuggestions(suggestions,coords){
    let spellCheckerDropdown = $(".spell-checker-dropdown");
    this.suggestionsList = suggestions;
    spellCheckerDropdown.css("top",coords.yCoord + 55);
    spellCheckerDropdown.css("left",coords.xCoord - 215);
    spellCheckerDropdown.show();
  }

  suggestionPicked(e){
    let target = $(e.target);
    let oldText = $(this.currentRightClickedEl.target).text();
    let textToBeReplaced = target.text();

    let cont = document.createElement("div");
    cont.innerHTML = this.resultText;
    let contItems = $(cont).children();

    $.each(contItems, function(index,item){
      if($(item).hasClass("correctable")){
        if($($(item).children()[0]).text() == oldText){
          if(textToBeReplaced.split(" ").length > 1){
            let temp = document.createElement("div");

            $.each((textToBeReplaced.split(" ")), function(index,word){
              let wordSpan = document.createElement("span");
              wordSpan.innerHTML = word;
              (<any>temp).append(wordSpan);
              (<any>temp).append(" ");
            }); 

            $($(cont).children()[index]).replaceWith(((temp).outerHTML).replace("<div>","").replace("</div>",""));
          }else{
            let textToBeReplacedwithoutSpace = textToBeReplaced.replace(/\s/g, "");
            let oldTextwithoutSpace = oldText.replace(/\s/g, "");

            if(textToBeReplacedwithoutSpace.length < oldTextwithoutSpace.length){
              let temp = document.createElement("div");

              $.each((oldText.split(" ")), function(index,word){
                let wordSpan = document.createElement("span");
                if(index == 0){
                  wordSpan.className = "corrected";  
                  let innerSpan = document.createElement("span");
                  innerSpan.innerHTML = word;

                  (<any>wordSpan).append(innerSpan);
                  (<any>temp).append(wordSpan);
                  (<any>temp).append(" ");
                  $(wordSpan).attr("data-suggestions",$(item).attr("data-suggestions"));
                }else{
                  wordSpan.innerHTML = word;
                  (<any>temp).append(wordSpan);
                  (<any>temp).append(" ");
                }
              }); 

              $($(cont).children()[index]).replaceWith(((temp).outerHTML).replace("<div>","").replace("</div>",""));
            }else{
              $(item).removeClass("correctable");
              $(item).addClass("corrected");
              $($(item).children()[0]).text(textToBeReplaced);
              $($(item).children()[0]).removeClass("correctable-inner");
            }
          }
        }
      }
    });

    this.resultText = ((cont).outerHTML).replace("<div>","").replace("</div>","");
    $(".spell-checker-dropdown").hide();

    this.reEvaluate();
  }

  reEvaluate() {
    let currentInput = "";

    let cont = document.createElement("div");
    cont.innerHTML = this.resultText;
    let contItems = $(cont).children();

    $.each(contItems, function(index,item){
      if(index == 0){
        if($(item).hasClass("correctable") || $(item).hasClass("corrected")){
          currentInput = $($(item).children()[0]).text();
        }else{
          currentInput = $(item).text(); 
        }
      }else{
        if($(item).hasClass("correctable") || $(item).hasClass("corrected")){
          currentInput = currentInput + " " + $($(item).children()[0]).text();
        }else{
          currentInput = currentInput + " " + $(item).text(); 
        }
      }
    });

    let obj = {
      text: currentInput
    }

    this.spellCheckerService.run(obj,false).then(result => {
      let newHtmlBody = "";
      let newSuggestions = (JSON.parse(result._body).results.suggestions);

      let cont = document.createElement("div");
      cont.innerHTML = this.resultText;
      let contItems = $(cont).children();

      let skipValue = 0;

      $.each(contItems, function(index,item){
        if(!$(item).hasClass("corrected")){
          if(newSuggestions[index + skipValue].length > 1){
            let suggestions = []
            $.each(newSuggestions[index + skipValue], function(index,suggestion) {
              suggestions.push(suggestion[0]);
            });
    
            let parsedSuggestions = JSON.stringify(suggestions);
            
            let word = "";
            if($(item).hasClass("correctable")){ 
              word = $($(item).children()[0]).text();
            }else{
              word = $(item).text();
            }

            newHtmlBody = newHtmlBody + "<span title='Right click to see suggested fixes' class='correctable' data-suggestions='"+ parsedSuggestions +"'><span class='correctable-inner'>"+ word +"</span></span>" + " ";
          }else{
            let word = "";
            if($(item).hasClass("correctable")){ 
              word = $($(item).children()[0]).text();
            }else{
              word = $(item).text();
            }
            newHtmlBody = newHtmlBody + "<span>"+ word +"</span>" + " ";
          }
        }else{
          let oldSuggestions = JSON.parse($(item).attr("data-suggestions"));
          let newSuggestionsFlag = false;
          $.each(newSuggestions[index + skipValue], function(newIndex,newSuggestion){
            if(oldSuggestions.indexOf(newSuggestion[0]) == -1){
              newSuggestionsFlag = true;
            }
          });

          if(newSuggestionsFlag){
            let suggestions = []
            $.each(newSuggestions[index + skipValue], function(index,suggestion) {
              suggestions.push(suggestion[0]);
            });
    
            let parsedSuggestions = JSON.stringify(suggestions);
            
            let word = "";
            if($(item).hasClass("correctable")){ 
              word = $($(item).children()[0]).text();
            }else{
              word = $(item).text();
            }

            newHtmlBody = newHtmlBody + "<span title='Right click to see suggested fixes' class='correctable' data-suggestions='"+ parsedSuggestions +"'><span class='correctable-inner'>"+ word +"</span></span>" + " ";
          }else{
            let parsedSuggestions = JSON.stringify(oldSuggestions);
            let word = $($(item).children()[0]).text();

            newHtmlBody = newHtmlBody + "<span title='Right click to see suggested fixes' class='corrected' data-suggestions='"+ parsedSuggestions +"'><span>"+ word +"</span></span>" + " ";
          }
        }

        let currentWord = "";
        if($(item).hasClass("correctable")){ 
          currentWord = $($(item).children()[0]).text();
        }else{
          currentWord = $(item).text();
        }
        if(currentWord.split(" ").length > 1){
          skipValue = (currentWord.split(" ").length) - 1;
        }
      });

      this.resultText = newHtmlBody;
    });
  }
}
