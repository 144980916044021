import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AlertsService } from '../alerts/alerts.service';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.css']
})

export class forgotPasswordFormComponent {
  @Input() alerts: any[] = [];
  @Output() finished = new EventEmitter();
  @Output() exit = new EventEmitter();
  showSpinner: boolean = false;
  step: number = 1;
  form = {
    username: '',
    verificationCode: '',
    newPassword: '',
    newPasswordConfirmation: ''
  };

  constructor(
    private auth: AuthService,
    private alert: AlertsService,
    private router: Router
  ) {
    this.alerts = this.alert.localAlerts;
  }


  forgotPassword(): void {
    if(this.step === 1) {
      this.requestVerificationCode();
    } else {
      if(this.form.newPassword != this.form.newPasswordConfirmation) {
        this.alert.addAlert({ type: 'danger', message: 'New password and new password confirmation filed do not match.' }, false);
      } else {
        this.confirmVerificationCode();
      }
    }
  }

  requestVerificationCode(): void {
    this.showSpinner = true;
    this.auth.requestForgotPasswordCode(this.form.username)
      .then(() => {
        this.showSpinner = false;
        this.alert.addAlert({ type: 'success', message: 'A verification code has been sent to your email.' }, false);
        this.step = 2;
      })
      .catch((err) => {
        this.showSpinner = false;
        this.alert.addAlert({ type: 'danger', message: err.message }, false);
      });
  }

  confirmVerificationCode(): void {
    this.showSpinner = true;
    this.auth.confirmForgotPasswordCode(this.form.username, this.form.verificationCode, this.form.newPassword)
      .then(() => {
        this.showSpinner = false;
        this.finished.emit({});
      })
      .catch((err) => {
        this.showSpinner = false;
        this.alert.addAlert({ type: 'danger', message: err.message }, false);
      });
  }

  exitComponent(): void {
    this.exit.emit(true);
  }
  
  closeAlert(i:number) {
    this.alerts.splice(i, 1);
  }

}
