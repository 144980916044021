import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertsService } from '../shared/alerts/alerts.service';
import { User } from '../models/user';
import { environment } from '../../environments/environment';

@Component({
  selector: 'pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})

export class PricingComponent {
  title: String = "Choose title";
  firstName: String = "";
  lastName: String = "";
  email: String = "";
  phoneNumber: String = "";
  company: String = "";
  sendRequestText: string = 'Send Your Request';
  sendRequestDisabled: boolean = false;
  userForm: string = 'signup';

  alerts: any[] = [];
  @Input() user: User = new User();

  constructor(
    private _snackBar: MatSnackBar,
    private alert: AlertsService
  ){}

  // ngOnDestroy(): void {
  //   let signinModal = <HTMLElement> document.querySelector("#modal-signin");
  //   let subscribeModal = <HTMLElement> document.querySelector("#modal-subscribe"); 
  //   if(signinModal.style.display != "block" && subscribeModal.style.display == "block"){
  //     document.querySelector("body").classList.remove("modal-open");
  //     document.querySelector(".modal-backdrop").parentNode.removeChild(document.querySelector(".modal-backdrop"));
  //     document.querySelector("body").style.padding = "0";
  //   }
  // }

  setForm(val: string): void {
    this.alert.localAlerts = [];
    this.alerts = this.alert.localAlerts;
    this.userForm = val;
  }

  handleUserCreated(user): void {
    this.user = user;
    this.setForm('signin');
    this.alert.addAlert({ type: 'success', message: 'You have successfully signed up.' }, false)
  }

  resetSteps() {
    this.setForm('signin');
  }

}
