import { Component, OnInit, Input ,ViewChild, ElementRef} from '@angular/core';
import { Http } from '@angular/http';
import { NgForm, FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';

import { NlpService } from './nlp.service';
import { AlertsService } from '../../shared/alerts/alerts.service';
import { AuthService } from '../../services/auth.service';
import { NerService } from '../ner/ner.service';
import { PosService } from '../pos/pos.service';
@Component({
  selector: 'app-nlp',
  templateUrl: './nlp-demo.component.html',
  styleUrls: ['./nlp-demo.component.css']
})
export class NlpDemoComponent implements OnInit {
  showSpinner: boolean = false;

  @Input() text: string;
  result: string='';
  nerResult: any = [];
  posResult: any = [];
  segResult: any = [];

  processHeader: string = '';
  loggedIn: boolean;
  samples: string[];

  constructor(
    private nlpService: NlpService,
    private nerService: NerService,
    private posService: PosService,
    private alerts: AlertsService,
    protected auth: AuthService
  ){
    this.loggedIn = this.auth.loggedIn();
    this.fetchSampleData();
  }
  
  setSample = (query): void => {
    this.text = query;

    if(!this.loggedIn)
      this.runNlpExamples();
    else
    this.runProcess();

  };

  ngOnInit(): void {}
  
  SwitchTabs(name : string) : void {
    if(name === 'ner' ) {
      this.processHeader = "Named Entity Recognizer";
      this.result = this.nerResult;
    }
    else if(name === 'pos'){
      this.processHeader = "Part of Speech Tagging";
      this.result = this.posResult;
    } 
    else if(name === 'seg') {
      this.processHeader = "Segmentation";
      this.result = this.segResult;
    }
    else return;
  }

  runProcess(): void {
    this.runNer();
    this.runPos();
    this.runSeg();
  }

  fetchSampleData(): void {
    this.nlpService.fetchSampleData()
      .then((data =>{
        this.samples = data;
    }));
  }

  runNer(): void {
    this.showSpinner = true;
    this.nlpService.runNlp(this.text, 'ner', !this.loggedIn)
      .then(result => {
        this.showSpinner = false;
        var end_res =  []
        for(var i=0; i< result["entities"].length; ++i) {
          end_res.push(result["entities"][i]["entity"] + ": " + result["entities"][i]["type_ar"]);
        }
        this.nerResult = end_res;

        if(this.nerResult.length !== 0 ) {
          this.result = this.nerResult;
          this.processHeader = "Named Entity Recognizer";
        }
      })
      .catch(err => {
        this.showSpinner = false;
         this.alerts.addAlert({ type: 'danger', message: err });
      });
  }


  runPos(): void {
    this.showSpinner = true;
    this.nlpService.runNlp(this.text, 'pos',!this.loggedIn)
      .then(result => {
        this.showSpinner = false;
        var end_res = []
        for(var i=0; i< result.length; ++i) {
          end_res.push(result[i]["word"] + ": " + result[i]["tag_ar"])
        }
        this.posResult = end_res;
        if(this.posResult.length !== 0 && this.result.length === 0 ) {
          this.processHeader = "Part of Speech Tagging";
          this.result = this.posResult;
        }
        
      })
      .catch(err => {
        this.showSpinner = false;
         this.alerts.addAlert({ type: 'danger', message: err });
      });
  }

  runSeg() : void {
    if(this.segResult.length !== 0 && this.result.length === 0) {
      this.processHeader = "Segmentation";
      this.result = this.segResult;
    }
  }

  runNlpExamples(): void {
    this.showSpinner = true;
    this.nlpService.runNlp(this.text, '',!this.loggedIn)
      .then(result => {
        this.showSpinner = false;
        var ner_res =  [];
        var pos_res =  [];
        for(var i=0; i< result["NER"]["entities"].length; ++i) {
          ner_res.push(result["NER"]["entities"][i]["entity"] + ": " + result["NER"]["entities"][i]["type_ar"]);
        }
        
        for(var i=0; i< result["POS"].length; ++i) {
          
          pos_res.push(result["POS"][i]["word"] + ": " + result["POS"][i]["tag_ar"])
        }
        this.nerResult = ner_res;
        this.posResult = pos_res;
        if(this.nerResult.length !== 0) {
          this.result = this.nerResult;
          this.processHeader = "Named Entity Recognizer";
        }
        else if(this.posResult.length !== 0 && this.result.length === 0 ) {
          this.processHeader = "Part of Speech Tagging";
          this.result = this.posResult;
        }
      })
      .catch(err => {
        this.showSpinner = false;
         this.alerts.addAlert({ type: 'danger', message: err });
      });
    
  }

}
