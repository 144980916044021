import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services/auth.service';
import { AlertsService } from '../alerts/alerts.service';
import { User } from '../../models/user';

@Component({
  selector: 'signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.css']
})

export class SignUpFormComponent {
  showSpinner: boolean = false;
  step: string = 'registration';

  @Input() alerts: any[] = [];
  @Input() user: User = new User();
  @Output() userCreated = new EventEmitter();

  constructor(
    private auth: AuthService,
    private alert: AlertsService,
    private router: Router
  ) {
    this.alerts = this.alert.localAlerts;
  }


  signup(): void {
    if(this.user.password != this.user.passwordConfirmation) {
      this.alert.addAlert({ type: 'danger', message: 'Password and password confirmation filled do not match.' }, false);
      return;
    }

    this.alert.localAlerts = [];
    this.alerts = this.alert.localAlerts;
    this.showSpinner = true;
    this.auth.signup(this.user)
      .then((cognitoUser) => {
        this.showSpinner = false;
        this.step = 'confirmation';
      })
      .catch((err) => {
        this.showSpinner = false;
        if(err.message === "User already exists"){
          err.message = "User name already exists"
        }
        this.alert.addAlert({ type: 'danger', message: err.message }, false);
      });
  }

  confirmRegistration(): void {
    this.alert.localAlerts = [];
    this.alerts = this.alert.localAlerts;
    this.showSpinner = true;
    this.auth.confirmRegistration(this.user)
      .then((cognitoUser) => {
        this.showSpinner = false;
        this.alert.addAlert({ type: 'success', message: 'You have successfully confirmed your account.' }, false);
        this.userCreated.emit(this.user);
      })
      .catch((err) => {
        this.showSpinner = false;
        this.alert.addAlert({ type: 'danger', message: err.message }, false);
      });
  }

  resendConfirmationCode(): void {
    this.alert.localAlerts = [];
    this.alerts = this.alert.localAlerts;
    this.showSpinner = true;
    this.auth.resendConfirmationCode(this.user)
      .then((cognitoUser) => {
        this.showSpinner = false;
        this.alert.addAlert({ type: 'warning', message: 'A confirmation code has been resent to your email' }, false);
      })
      .catch((err) => {
        this.showSpinner = false;
        this.alert.addAlert({ type: 'danger', message: err.message }, false);
      });
  }

  closeAlert(i:number) {
    this.alerts.splice(i, 1);
  }

}
