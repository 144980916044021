export class User {
  email: string;
  username: string;
  password?: string;
  passwordConfirmation?: string;
  phoneNumber?: string;
  promoCode?: string;
  apiKey?: string;
  authToken?: string;
  confirmationCode?: string;

  fromJSON(json) {
    var props = ['email', 'username', 'password', 'phoneNumber', 'promoCode', 'apiKey'];
    for (var propName in json){
      if(props.includes(propName))
        this[propName] = json[propName];
    }
    return this;
  }
}
