import { Component, OnInit, Input } from '@angular/core';
import { AllInOneService } from './all-in-one.service';
import { AlertsService } from '../../shared/alerts/alerts.service';
import { AuthService } from '../../services/auth.service'
import { FormGroup, FormControl } from '@angular/forms';
import { QuestionSimilarityService } from '../question-similarity/question-similarity.service';
import { Http } from '@angular/http';
import { RecorderService } from '../../shared/recorder/recorder.service';
import { STTService } from '../stt/stt.service';

@Component({
  selector: 'all-in-one',
  templateUrl: './all-in-one.component.html',
  styleUrls: ['./all-in-one.component.css']
})
export class AllInOneComponent implements OnInit {

  loggedIn: boolean;
  samples: string[];
  text: string;
  text1: string;
  text2: string;
  service: string;
  serviceTitle: string;
  serviceDesc: string;
  serviceIcon: string;
  resultText: string;
  resultList: string[];
  resultBlob: any;
  customText = false;
  customList = false;
  cachedAnswers = {}
  userForm = new FormGroup({
    removePunctuation: new FormControl(),
    removeNumbers: new FormControl(),
    removeTatweel: new FormControl(),
    removeNonArabic: new FormControl(),
    removeTashkeel: new FormControl(),
    removeStopWords: new FormControl(),
    normalizeAlif: new FormControl(),
    normalizeHamza: new FormControl(),
    stripHamza: new FormControl(),
    endingHaToTa: new FormControl(),
    endingTaToHa: new FormControl(),
  });
  sampleText = "يعتقد أن ماليزيا أصبحت غنيّة بالحياة البشرية قبل 400 قرن من الآن، وفي أوائل القرن الأول الميلادي انتقل المستوطنون والتجار من الصين والهند إلى ماليزيا، حيث قاموا باستغلالها عن طريق بناء بلدات ساحلية وموانئ تجارية في القرنين الثاني والثالث الميلادي، كما أثّر وجودهم على الثقافة المحلية الماليزية."
  sampleQuestion1 = "ما هو مناخ اميريكا في فصل الشتاء";
  sampleQuestion2 = "ما مناخ الولايات المتحدة العام في فصل الشتاء";
  result: string;
  audioSamples: any[] = [
    { id: 1, url: '/assets/samples/voice/stt/1.wav' },
    { id: 2, url: '/assets/samples/voice/stt/2.wav' }
  ];
  audioId: string;
  @Input() audio: Blob;

  constructor(private allinOneService: AllInOneService,
    private alerts: AlertsService,
    private auth: AuthService,
    private questionSimilarityService: QuestionSimilarityService,
    private http: Http,
    public recorder: RecorderService,
    private sttService: STTService) {
    this.loggedIn = this.auth.loggedIn()
    this.service = 'tashkeelService';
  }

  ngOnInit() {
    this.setupService()
    if(!this.loggedIn)
      this.text = this.sampleText
  }

  onServiceChange(e: any) {
    document.querySelector('.selected').classList.remove('selected')
    e.srcElement.classList.add('selected')
    // console.log(e.srcElement.dataset['val'])
    this.service = e.srcElement.dataset['val']
    this.samples = []
    this.setupService()
  }

  setupService() {

    // this.allinOneService.fetch(this.service).then((data => {
    // this.samples = data
    //   if (this.samples != null) {
    //     this.setSample(this.samples[0]['query'])
    //   }
    // }));

    let serviceObj = Reflect.get(this.allinOneService, this.service)
    this.customText = serviceObj.customText
    this.customList = serviceObj.customList
    this.serviceTitle = serviceObj.title
    this.serviceDesc = serviceObj.desc
    this.serviceIcon = serviceObj.icon

    this.resultText = null
    this.resultList = null
    this.resultBlob = null

    if (this.loggedIn) {
      // if (this.service == 'normalizeService') {
      //   this.userForm.patchValue({
      //     removePunctuation: true,
      //     removeNumbers: true,
      //     removeTatweel: true,
      //     removeNonArabic: true,
      //     removeTashkeel: true,
      //     removeStopWords: true,
      //     normalizeAlif: true,
      //     endingHaToTa: true,
      //   });
      //   this.getResult({ text: this.text, filters: this.getFilters() })
      // } else if (this.service == 'QuestionSimilarityService'){
      //   let module = this;
      //   this.text1 = this.sampleQuestion1
      //   this.text2 = this.sampleQuestion2
      //   this.questionSimilarityService.run({ q1: this.text1, q2: this.text2, type: "q2q"}).then(function(response){
      //     module.resultText = (response.json().results["similarity"] == 0 ? "غير متشابه" : "متشابه") + "  " + (100 * response.json().results["confidence"]).toFixed(1) + "%";
      //   });
      // } else if (this.service == 'STTService'){
        
      // } else {
      //   this.getResult(this.text)
      // }
    } else {
      if(this.service == 'QuestionSimilarityService'){
        this.text1 = this.sampleQuestion1
        this.text2 = this.sampleQuestion2
      }else{
        this.text = this.sampleText
      }

      if (this.service == 'normalizeService') {

        this.userForm.patchValue({
          removePunctuation: true,
          removeNumbers: true,
          removeTatweel: true,
          removeNonArabic: true,
          removeTashkeel: true,
          removeStopWords: true,
          normalizeAlif: true,
          endingHaToTa: true,
        });
        if (!this.loggedIn) {
          this.userForm.get('removePunctuation').disable();
          this.userForm.get('removeNumbers').disable();
          this.userForm.get('removeTatweel').disable();
          this.userForm.get('removeNonArabic').disable();
          this.userForm.get('removeTashkeel').disable();
          this.userForm.get('removeStopWords').disable();
          this.userForm.get('normalizeAlif').disable();
          this.userForm.get('normalizeHamza').disable();
          this.userForm.get('stripHamza').disable();
          this.userForm.get('endingHaToTa').disable();
          this.userForm.get('endingTaToHa').disable();
        }

      }

      if(this.service == "STTService"){
        this.setAudioSample(1);
      }else{
        this.setSample(this.text)
      }
    }
    
  }

  onAnalyzeClick(e: any) {
    this.resultText = "";
    if (this.service == 'normalizeService') {
      this.getResult({ text: this.text, filters: this.getFilters() })
    } else if(this.service == 'QuestionSimilarityService') {
      let module = this;
      this.questionSimilarityService.run({ q1: this.text1, q2: this.text2, type: "q2q"}).then(function(response){
        module.resultText = (response.json().results["similarity"] == 0 ? "غير متشابه" : "متشابه") + "  " + (100 * response.json().results["confidence"]).toFixed(1) + "%";
      });
    } else {
      this.getResult(this.text)
    }
  }

  getResult(query) {
    if (query != null) {
      // return result if cached
      let dirty = true
      if (this.loggedIn && this.cachedAnswers[this.service]) {
        let cache = this.cachedAnswers[this.service]
        if (this.service != 'normalizeService' && this.service != 'ttsService')
          dirty = (cache.text != this.text)
      }

      if (!dirty) {
        let cache = this.cachedAnswers[this.service]
        this.resultText = cache.resultText
        this.resultList = cache.resultList
        this.resultBlob = cache.resultBlob
      } else {
        // no cached result for this service/text
        this.allinOneService.run(this.service, query)
          .then(result => {
            try {
              let rawResult = this.allinOneService.present(this.service, result, query)
              let serviceObj = Reflect.get(this.allinOneService, this.service)
              if (rawResult != null) {
                if ('resultText' in serviceObj) {
                  this.resultText = rawResult.resultText
                }
                if ('resultList' in serviceObj) {
                  this.resultList = (rawResult.resultList == null || rawResult.resultList.length <= 0) ? null : rawResult.resultList
                }
                if ('resultBlob' in serviceObj) {
                  rawResult.resultBlob.then(rsp => this.resultBlob = rsp)
                }
                // cache answer
                this.cachedAnswers[this.service] = {
                  text: this.text,
                  resultText: this.resultText,
                  resultList: this.resultList,
                  resultBlob: this.resultBlob
                }
              }
            } catch (error) {
              console.error(error);
            }
          })
      }
    }
  }

  setSample(query) {
    if (this.service == 'normalizeService') {
      this.text = query
      this.getResult({ text: this.text, filters: this.getFilters() })
    } else {
      this.text = query;
      this.getResult(query)
    }
  }

  getFilters(): string {
    var filters = []
    if (this.userForm.get('removePunctuation').value)
      filters.push('removePunctuation')
    if (this.userForm.get('removeNumbers').value)
      filters.push('removeNumbers')
    if (this.userForm.get('removeTatweel').value)
      filters.push('removeTatweel')
    if (this.userForm.get('removeNonArabic').value)
      filters.push('removeNonArabic')
    if (this.userForm.get('removeTashkeel').value)
      filters.push('removeTashkeel')
    if (this.userForm.get('removeStopWords').value)
      filters.push('removeStopWords')
    if (this.userForm.get('normalizeAlif').value)
      filters.push('normalizeAlif')
    if (this.userForm.get('normalizeHamza').value)
      filters.push('normalizeHamza')
    if (this.userForm.get('stripHamza').value)
      filters.push('striphamza')
    if (this.userForm.get('endingHaToTa').value)
      filters.push('endingHaToTa')
    if (this.userForm.get('endingTaToHa').value)
      filters.push('endingTaToHa')

    let res = filters.join(',')
    if (res == "")
      res = "none"
    return res
  }

  onHaToTaChange() {
    if (this.userForm.get('endingTaToHa').value && this.userForm.get('endingHaToTa').value) {
      this.userForm.patchValue({ endingTaToHa: false })
    }
  }

  onTaToHaChange() {
    if (this.userForm.get('endingHaToTa').value && this.userForm.get('endingTaToHa').value) {
      this.userForm.patchValue({ endingHaToTa: false })
    }
  }

  setAudioSample(id): void {
    this.http
      .get(`/assets/samples/stt.json`)
      .toPromise()
      .then(rsp => {
        let results = rsp.json();
        if(!id)
          id = 1;
        let res = results.find(x => x.id == id).result;
        this.resultText = res.text;
      });
  }

  convertToText(): void {
    var recorder = this.recorder.recordRTC;
    if(!recorder){
      this.alerts.addAlert({ type: 'danger', message: 'Something went wrong, please refresh the page and try again.' });
      return;
    }
    this.resultText = null;
    this.audio = recorder.getBlob();
    this.sttService.getText(this.audio)
      .then((rsp) => {
        this.resultText = rsp.text;
        this.audioId = rsp.id;
      })
      .catch((err) => {
        if(err.status === 422) {
          this.alerts.addAlert({ type: 'danger', message: 'I did not understand this, please try again.' });
        } else {
          this.alerts.addAlert({ type: 'danger', message: 'Something went wrong, please try again later.' });
        }
      });
  }
}
