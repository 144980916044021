import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertsService } from '../shared/alerts/alerts.service';
import { User } from '../models/user';

@Component({
  selector: 'signin-page',
  templateUrl: './signin-page.component.html',
  styleUrls: ['./signin-page.component.css']
})
export class SignInPageComponent {
  showSpinner: boolean = false;
  userForm: string = 'signin';

  alerts: any[] = [];
  @Input() user: User = new User();

  constructor(
    private alert: AlertsService,
    private route: ActivatedRoute
  ) {
    this.alerts = this.alert.localAlerts;

    this.route.data.subscribe((value) => {
      if (value.form)
        this.setForm(value.form);
    });
  }

  setForm(val: string): void {
    this.alert.localAlerts = [];
    this.alerts = this.alert.localAlerts;
    this.userForm = val;
  }

  handleUserCreated(user): void {
    this.user = user;
    this.setForm('signin');
    this.alert.addAlert({ type: 'success', message: 'You have successfully signed up.' }, false)
  }

}