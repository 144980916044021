import { Component, Input } from '@angular/core';


@Component({
  selector: 'samples',
  templateUrl: './samples.component.html',
  styleUrls: ['./samples.component.css']
})

export class SamplesComponent {

  @Input() sampleType: string;
  @Input() sampleData: any[];
  @Input() setSample: any;
  @Input() loggedIn: boolean;

  constructor(){}

}
