import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AllInOneService } from '../all-in-one/all-in-one.service';
import { AlertsService } from '../../shared/alerts/alerts.service';
import { AuthService } from '../../services/auth.service'
import { FormGroup, FormControl } from '@angular/forms';
import { Http } from '@angular/http';
import { RecorderService } from '../../shared/recorder/recorder.service';
import { STTService } from '../stt/stt.service';

@Component({
  selector: 'app-single-api',
  templateUrl: './single-api.component.html',
  styleUrls: ['./single-api.component.css']
})

export class SingleApiComponent implements OnInit {
  service: string;
  serviceTitle: string;
  serviceDesc: string;
  title: string;
  loggedIn: boolean;
  samples: string[];
  text: string;
  text1: string;
  text2: string;
  resultText: string;
  resultList: string[];
  resultBlob: any;
  customText = false;
  customList = false;
  userForm = new FormGroup({
    removePunctuation: new FormControl(),
    removeNumbers: new FormControl(),
    removeTatweel: new FormControl(),
    removeNonArabic: new FormControl(),
    removeTashkeel: new FormControl(),
    removeStopWords: new FormControl(),
    normalizeAlif: new FormControl(),
    normalizeHamza: new FormControl(),
    stripHamza: new FormControl(),
    endingHaToTa: new FormControl(),
    endingTaToHa: new FormControl(),
  });
  result: string;
  audioSamples: any[] = [
    { id: 1, url: '/assets/samples/voice/stt/1.wav' },
    { id: 2, url: '/assets/samples/voice/stt/2.wav' }
  ];
  audioId: string;
  @Input() audio: Blob;

  constructor(private route: ActivatedRoute,
    private allinOneService: AllInOneService,
    private alerts: AlertsService,
    private auth: AuthService,
    private http: Http,
    public recorder: RecorderService,
    private sttService: STTService) { 
      this.loggedIn = this.auth.loggedIn()
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.service = data.service;
      this.serviceTitle = data.service_title;
      this.serviceDesc = data.service_desc;
      this.title = data.title;
      let serviceObj = Reflect.get(this.allinOneService, this.service)
      this.customText = serviceObj.customText
      this.customList = serviceObj.customList
    });

    if(this.service != 'STTService'){
      this.setupService()
    }else{
      this.setAudioSample(1,true);
    }
  }

  setupService() {
    this.allinOneService.fetch(this.service).then((data => {
    this.samples = data
      if (this.samples != null) {
        this.setSample(this.samples[0]['query'],true)
      }
    }));
  }

  setSample(query,firstTime) {
    if (this.service == 'normalizeService') {
      if(!firstTime){
        this.text = query
        this.getResult({ text: this.text, filters: this.getFilters() })
      }
    }else if(this.service == 'QuestionSimilarityService'){
      if(!firstTime){
        this.text1 = query.split("|")[0];
        this.text2 = query.split("|")[1];
        this.getResult({ q1: this.text1, q2: this.text2, type: 'q2q' })
      }
    }else {
      if(!firstTime){
        this.text = query;
        this.getResult(query)
      }
    }
  }

  getResult(query) {
    if (query != null) {
      // no cached result for this service/text
      this.allinOneService.run(this.service, query)
        .then(result => {
          try {
            let rawResult = this.allinOneService.present(this.service, result, query)
            let serviceObj = Reflect.get(this.allinOneService, this.service)
            if (rawResult != null) {
              if ('resultText' in serviceObj) {
                this.resultText = rawResult.resultText
              }
              if ('resultList' in serviceObj) {
                this.resultList = (rawResult.resultList == null || rawResult.resultList.length <= 0) ? null : rawResult.resultList
              }
              if ('resultBlob' in serviceObj) {
                rawResult.resultBlob.then(rsp => this.resultBlob = rsp)
              }
    
            }
          } catch (error) {
            console.error(error);
          }
        })
    }
  }

  getFilters(): string {
    var filters = []
    if (this.userForm.get('removePunctuation').value)
      filters.push('removePunctuation')
    if (this.userForm.get('removeNumbers').value)
      filters.push('removeNumbers')
    if (this.userForm.get('removeTatweel').value)
      filters.push('removeTatweel')
    if (this.userForm.get('removeNonArabic').value)
      filters.push('removeNonArabic')
    if (this.userForm.get('removeTashkeel').value)
      filters.push('removeTashkeel')
    if (this.userForm.get('removeStopWords').value)
      filters.push('removeStopWords')
    if (this.userForm.get('normalizeAlif').value)
      filters.push('normalizeAlif')
    if (this.userForm.get('normalizeHamza').value)
      filters.push('normalizeHamza')
    if (this.userForm.get('stripHamza').value)
      filters.push('striphamza')
    if (this.userForm.get('endingHaToTa').value)
      filters.push('endingHaToTa')
    if (this.userForm.get('endingTaToHa').value)
      filters.push('endingTaToHa')

    let res = filters.join(',')
    if (res == "")
      res = "none"
    return res
  }

  onAnalyzeClick(e: any) {
    if (this.service == 'normalizeService') {
      this.getResult({ text: this.text, filters: this.getFilters() })
    } else if(this.service == 'QuestionSimilarityService') {
      this.getResult({ q1: this.text1,q2: this.text2, type:"q2q" })
    } else {
      this.getResult(this.text)
    }
    
    this.resultText = "";
  }

  setAudioSample(id,firstTime): void {
    this.http
      .get(`/assets/samples/stt.json`)
      .toPromise()
      .then(rsp => {
        let results = rsp.json();
        if(!id)
          id = 1;
        let res = results.find(x => x.id == id).result;
        if(!firstTime)
          this.resultText = res.text;
      });
  }

  convertToText(): void {
    var recorder = this.recorder.recordRTC;
    if(!recorder){
      this.alerts.addAlert({ type: 'danger', message: 'Something went wrong, please refresh the page and try again.' });
      return;
    }
    this.resultText = null;
    this.audio = recorder.getBlob();
    this.sttService.getText(this.audio)
      .then((rsp) => {
        this.resultText = rsp.text;
        this.audioId = rsp.id;
      })
      .catch((err) => {
        if(err.status === 422) {
          this.alerts.addAlert({ type: 'danger', message: 'I did not understand this, please try again.' });
        } else {
          this.alerts.addAlert({ type: 'danger', message: 'Something went wrong, please try again later.' });
        }
      });
  }
}
