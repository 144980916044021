import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';

import { HttpClient } from '../../services/http-client.service';
import { AuthService } from '../../services/auth.service';
import { UtilService } from '../../services/util.service';
import { environment } from '../../../environments/environment';


@Injectable()
export class NlpService {

  private apiUrl = 'https://api.mawdu.com/text/' + environment.oldApiVersion;
  private options = {
    headers: new Headers()
  };

  constructor(
    protected http: HttpClient,
    protected auth: AuthService,
    private util: UtilService
  ){ }

  fetchSampleData(): Promise<any> {
    return this.http
      .get(`/assets/samples/nlp.json`)
      .then(rsp => {
        return rsp.json();
      });
  }

  runNlp(text: string, model:string = 'pos', sample: boolean = false): Promise<string[]> {
    if (sample) {
      return this.fetchSampleData()
        .then(samples => {
          return samples.find(sampleObj => sampleObj.query === text).results;
        })
    }
    let params = { text: text };
    return this.http
      .get(`${this.apiUrl}/`+model+`?${this.util.toURLParams(params)}`, this.options)
      .then(rsp => rsp.json().results)
      .catch((err) => Promise.reject(err));
  }

}
