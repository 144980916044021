import { Component, OnInit, Input } from '@angular/core';
import { AlertsService } from '../shared/alerts/alerts.service';
import { User } from '../models/user';

@Component({
  selector: 'landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})

export class LandingPageComponent implements OnInit {

  @Input() user: User = new User();

  showSpinner: boolean = false;
  userForm: string = 'signup';
  alerts: any[] = [];
  weatherQuestionVisible: boolean = true;
  teleQuestionVisible: boolean = false;
  bankingQuestionVisible: boolean = false;
  airlinesQuestionVisible: boolean = false;
  selectedService: number = 0;
  audio = new Audio();
  audioType: string;
  audioWeatherBackgroundPosition: string = "0px";
  audioWeatherAnimationInterval;
  audioTeleBackgroundPosition: string = "0px";
  audioTeleAnimationInterval;
  audioBankingBackgroundPosition: string = "0px";
  audioBankingAnimationInterval;
  audioAirlinesBackgroundPosition: string = "0px";
  audioAirlinesAnimationInterval;
  ribbonVisible: boolean = true;

  constructor(
    private _alerts: AlertsService
  ){}

  ngOnInit(): void {
    this._alerts.alerts = [];
    this.audio.onended = () => {
      this.stopAudioAnimation();
    }
  }

  setForm(val: string): void {
    this.alerts = [];
    this.userForm = val;
  }

  handleUserCreated(user): void {
    this.user = user;
    this.setForm('signin');
    this._alerts.addAlert({ type: 'success', message: 'You have successfully signed up.' })
  }

  tabChanged(event) {
    this.weatherQuestionVisible = false;
    this.teleQuestionVisible = false;
    this.bankingQuestionVisible = false;
    this.airlinesQuestionVisible = false;

    switch(event.index){
      case 0:
        this.weatherQuestionVisible = true;
        break;
      case 1:
        this.teleQuestionVisible = true;
        break;
      case 2:
        this.bankingQuestionVisible = true;
        break;
      case 3:
        this.airlinesQuestionVisible = true;
        break;
    }

    this.stopAudioAnimation();
    this.audio.pause();
  }

  serviceChanged(event) {
    let allButtons = [].slice.call(document.querySelectorAll(".button-item"));
    for (let button of allButtons) {
      button.classList.remove("selected");
    }
    event.target.classList.add("selected");

    this.selectedService = event.target.getAttribute("data-index");
  }

  playCardAudio(event) {
    let type = event.target.getAttribute("data-type");

    this.stopAudioAnimation();
    if(!this.audio.paused){
      if(this.audioType == type){
        this.audio.pause();
        return false;
      }
    }

    switch(type){
      case "weather-audio":
        this.audio.src = "../../assets/audio/how-it-works/weather-audio.mp3";
        break;
      case "tele-audio":
        this.audio.src = "../../assets/audio/how-it-works/tele-audio.mp3";
        break;
      case "banking-audio":
        this.audio.src = "../../assets/audio/how-it-works/banking-audio.mp3";
        break;
      case "airlines-audio":
        this.audio.src = "../../assets/audio/how-it-works/airlines-audio.mp3";
        break;
    }

    this.audioType = type;
    this.audio.load();
    this.audio.play();
    this.playAudioAnimation(type);
  }

  stopAudioAnimation() {
    let module = this;
    clearInterval(module.audioWeatherAnimationInterval);
    clearInterval(module.audioTeleAnimationInterval);
    clearInterval(module.audioBankingAnimationInterval);
    clearInterval(module.audioAirlinesAnimationInterval);
  }

  playAudioAnimation(type) {
    let module = this;

    switch(type){
      case "weather-audio":
        this.audioWeatherAnimationInterval = setInterval(function(){
          module.audioWeatherBackgroundPosition = (((parseInt(module.audioWeatherBackgroundPosition.split("px")[0])) + 1) + "px");
        },25);
        break;
      case "tele-audio":
        this.audioTeleAnimationInterval = setInterval(function(){
          module.audioTeleBackgroundPosition = (((parseInt(module.audioTeleBackgroundPosition.split("px")[0])) + 1) + "px");
        },25);
        break;
      case "banking-audio":
        this.audioBankingAnimationInterval = setInterval(function(){
          module.audioBankingBackgroundPosition = (((parseInt(module.audioBankingBackgroundPosition.split("px")[0])) + 1) + "px");
        },25);
        break;
      case "airlines-audio":
        this.audioAirlinesAnimationInterval = setInterval(function(){
          module.audioAirlinesBackgroundPosition = (((parseInt(module.audioAirlinesBackgroundPosition.split("px")[0])) + 1) + "px");
        },25);
        break;
    }
  }

  slideLeft() {
    let cardsCont = <HTMLElement> document.querySelector("#news-feed .cards-cont");
    let cardsContVisibleWidth = cardsCont.offsetWidth;
    let cardsContActualWidth = cardsCont.scrollWidth;
    let diff = cardsContActualWidth - cardsContVisibleWidth;
    let slideAmount = Math.min(diff,cardsContVisibleWidth);
    let oldSlidePosition = cardsCont.scrollLeft;
    let newPosition = oldSlidePosition - slideAmount;
    cardsCont.scrollTo({
      left: newPosition,
      behavior: "smooth"
    });

    if(newPosition <= 0){
      let arrowRight = <HTMLElement> document.querySelector(".arrow-right");
      let arrowRightDisabled = <HTMLElement> document.querySelector(".arrow-right-disabled");
      arrowRight.style.display = "block";
      arrowRightDisabled.style.display = "none";

      let arrowLeft = <HTMLElement> document.querySelector(".arrow-left");
      let arrowLeftDisabled = <HTMLElement> document.querySelector(".arrow-left-disabled");
      arrowLeft.style.display = "none";
      arrowLeftDisabled.style.display = "block";
    }else{
      let arrowRight = <HTMLElement> document.querySelector(".arrow-right");
      let arrowRightDisabled = <HTMLElement> document.querySelector(".arrow-right-disabled");
      arrowRight.style.display = "block";
      arrowRightDisabled.style.display = "none";
    }
  }

  slideRight() {
    let cardsCont = <HTMLElement> document.querySelector("#news-feed .cards-cont");
    let cardsContVisibleWidth = cardsCont.offsetWidth;
    let cardsContActualWidth = cardsCont.scrollWidth;
    let diff = cardsContActualWidth - cardsContVisibleWidth;
    let slideAmount = Math.min(diff,cardsContVisibleWidth);
    let oldSlidePosition = cardsCont.scrollLeft;
    let newPosition = oldSlidePosition + slideAmount;
    cardsCont.scrollTo({
      left: newPosition,
      behavior: "smooth"
    });

    if((cardsContActualWidth - newPosition) <= cardsContVisibleWidth){
      let arrowRight = <HTMLElement> document.querySelector(".arrow-right");
      let arrowRightDisabled = <HTMLElement> document.querySelector(".arrow-right-disabled");
      arrowRight.style.display = "none";
      arrowRightDisabled.style.display = "block";

      let arrowLeft = <HTMLElement> document.querySelector(".arrow-left");
      let arrowLeftDisabled = <HTMLElement> document.querySelector(".arrow-left-disabled");
      arrowLeft.style.display = "block";
      arrowLeftDisabled.style.display = "none";
    }else{
      let arrowLeft = <HTMLElement> document.querySelector(".arrow-left");
      let arrowLeftDisabled = <HTMLElement> document.querySelector(".arrow-left-disabled");
      arrowLeft.style.display = "block";
      arrowLeftDisabled.style.display = "none";
    }
  }

  servicesTabChanged() {
    if(this.selectedService == 0 || this.selectedService == 6 || this.selectedService == 12){
      this.ribbonVisible = true;
    }else{
      this.ribbonVisible = false;
    }
  }
}
