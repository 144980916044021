import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';

import { HttpClient } from '../../services/http-client.service';
import { AuthService } from '../../services/auth.service';
import { UtilService } from '../../services/util.service';
import { environment } from '../../../environments/environment';


@Injectable()
export class SearchService {

  private apiUrl = 'https://api.mawdu.com/text/' + environment.oldApiVersion;
  private options = {
    headers: new Headers()
  };

  constructor(
    protected http: HttpClient,
    protected auth: AuthService,
    private util: UtilService
  ){ }

  fetchSampleData(): Promise<any> {
    return this.http
      .get(`/assets/samples/search.json`)
      .then(rsp => {
        return rsp.json();
      });
  }

  search(query: string, sample: boolean = false, source: string = 'mawdoo3,wikipedia', start: number = 0, limit: number = 10): Promise<any> {
    if (sample) {
      return this.fetchSampleData()
        .then(samples => {
          return samples.find(sampleObj => sampleObj.query === query).result;
        })
    }

    var params = { query: query, source: source, start: start, limit: limit };
    return this.http.get(`${this.apiUrl}/search/?${this.util.toURLParams(params)}`, this.options)
      .then(rsp => rsp.json())
      .catch(err => err);
  }

}
