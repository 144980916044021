import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { STTService } from '../../apis/stt/stt.service';
import { AlertsService } from '../alerts/alerts.service';
import { RecorderService } from '../recorder/recorder.service';

@Component({
  selector: 'stt-contribution',
  templateUrl: 'stt-contribution.component.html',
  styleUrls: [ 'stt-contribution.component.css' ]
})

export class STTContributionComponent implements OnInit {

  wantToContribute: boolean = false;
  modifiedQuery: string;
  @Input() audioId: string;
  @Input() query: string;
  @Output() contributed = new EventEmitter();


  constructor(
    private sttService: STTService,
    private _alerts: AlertsService,
    private recorder: RecorderService
  ){}

  ngOnInit(): void {
    this.modifiedQuery = this.query;
  }

  setWantToContribute(val): void {
    this.wantToContribute = val;
  }

  contribute(): void {
    this.sttService.contribute(this.audioId, this.modifiedQuery, localStorage.getItem('uid'))
      .then(rsp => {
        this.setWantToContribute(false);
        this._alerts.addAlert({ type: 'success', message: 'Thank you for your contribution.' });
        this.contributed.emit({ response: rsp, modifiedQuery: this.modifiedQuery });
      })
      .catch(err => {
        this.setWantToContribute(false);
        this._alerts.addAlert({ type: 'error', message: 'Something went wrong, please try again later.' });
      });
  }


}
