import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { HttpClient } from '../../services/http-client.service';
import { AuthService } from '../../services/auth.service';
import { UtilService } from '../../services/util.service';
import { environment } from '../../../environments/environment';
import { AbstractNLPService } from '../AbstractNLPService'

@Injectable({ providedIn: 'root' })
export class SegmentService extends AbstractNLPService {

  apiURL = 'https://api.mawdu.com/' + environment.newApiVersion + '/segment';
  samplesFile = '/assets/samples/segment.json'
  method = 'post'
  options = { headers: new Headers({ 'content-type': 'application/json' }) }
  resultList = []
  title = 'Morphological Segmented Text'
  desc = 'Morphological Segmentation API is a service by Mawdoo3 AI that obtains the morphological segments from your text.'
  icon = '/assets/img/api-icons/morphological-Segmentation.png'
  constructor(protected http: HttpClient, protected auth: AuthService, protected util: UtilService) {
    super(http, auth, util)
  }

  present(response: any) {
    try {
      this.resultList = response.json().result
    } catch {
      this.resultList = response.result
    }
    return { resultList: this.resultList }
  }

}
