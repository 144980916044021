import { Component, OnInit, Input } from '@angular/core';
import { TTSServiceSaudi } from './tts-saudi.service';
import { TashkeelService } from '../tashkeel/tashkeel.service';
import { AlertsService } from '../../shared/alerts/alerts.service';
import { AuthService } from '../../services/auth.service';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'tts-saudi-demo',
  templateUrl: './tts-saudi-demo.component.html',
  styleUrls: ['./tts-saudi-demo.component.css']
})

export class TTSSaudiDemoComponent implements OnInit {
  showSpinner: boolean = false;

  @Input() withTashkeel: boolean = false;
  @Input() text: string = "";
  loggedIn: boolean;
  resultBlob: SafeUrl
  serviceTitle = ''
  mawdoo3 = {
    visible: false,
    blobUrl: null
  };

  bing = {
    visible: false,
    blobUrl: null
  };

  readspeaker = {
    visible: false,
    blobUrl: null
  };

  samples: string[];

  constructor(
    private ttsService: TTSServiceSaudi,
    private tashkeelService: TashkeelService,
    private _alerts: AlertsService,
    private auth: AuthService,
  ) {
    this.serviceTitle = this.ttsService.title
    this.loggedIn = this.auth.loggedIn();
    this.fetchSampleData();
  }

  ngOnInit(): void { }


  fetchSampleData(): void {
    this.ttsService.fetchSamples()
      .then((data => {
        this.samples = data
        if (this.samples != null) {
          this.setSample(this.samples[0]['query'])
        }
      }));
  }

  setSample(query): void {
    this.text = query;
    this.resetPlayers();
    this.showSpinner = true;
    this.getVoice();

  }

  private resetPlayers(): void {
    this.mawdoo3.visible = false;
    this.mawdoo3.blobUrl = false;

    this.bing.visible = false;
    this.bing.blobUrl = null;

    this.readspeaker.visible = false;
    this.readspeaker.blobUrl = null;
  }

  convertToSpeech(): void {
    this.resetPlayers();
    this.showSpinner = true;

    // check with Tashkeel 
    if (this.withTashkeel) {
      this.tashkeelService.run(this.text)
        .then(result => {
          this.text = result;
          this.getVoice();
        })
        .catch(err => {
          this._alerts.addAlert({ type: 'danger', message: err.message });
        });
    } else {
      this.getVoice();
    }
  }

  private getVoice() {
    return this.ttsService.run(this.text, !this.loggedIn)
      .then((blob) => {
        this.ttsService.present(blob, this.text).resultBlob
          .then(res => {
            this.resultBlob = res;
            this.showSpinner = false;
          })
      })
      .catch((err) => { this._alerts.addAlert({ type: 'danger', message: err.message }); })
  }

}
