import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Injectable()
export class UserService {

  constructor(
    private auth: AuthService
  ){}

}
