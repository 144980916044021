import { Injectable } from '@angular/core';
import { HttpClient } from '../../services/http-client.service';
import { AuthService } from '../../services/auth.service';
import { UtilService } from '../../services/util.service';
import { environment } from '../../../environments/environment';
import { AbstractNLPService } from '../AbstractNLPService'

@Injectable({ providedIn: 'root' })
export class DialectService extends AbstractNLPService {

  apiURL = 'https://api.mawdu.com/' + environment.newApiVersion + '/dialect-detection/detect-main-dialect';
  samplesFile = '/assets/samples/dialect.json'
  method = 'post'
  translation = {
    gulf: 'لهجة خليجية',
    msa: 'عربية فصحى',
    levantine: 'لهجة شامية',
    egyptian: 'لهجة مصرية'
  }

  resultText = ''
  title = 'Dialect'
  desc = 'Dialect Detection API is a service by Mawdoo3 AI that detects different Arabic dialects.'
  icon = '/assets/img/api-icons/voice-recognition.png'
  constructor(protected http: HttpClient, protected auth: AuthService, protected util: UtilService) {
    super(http, auth, util)
  }

  present(response: any) {
    try {
      this.resultText = this.translation[response.json().main_dialect]
    } catch {
      this.resultText = this.translation[response.main_dialect]
    }
    return { resultText: this.resultText }
  }

}
