import { Component, Input, ViewChild, ContentChild, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AlertsService } from './shared/alerts/alerts.service';
import { Meta, Title } from '@angular/platform-browser';

import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    public _alerts: AlertsService,
    private auth: AuthService,
    private router: Router,
    private meta: Meta,
    private title: Title
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
        window.scrollTo(0, 0)
      }

      let navMenu = document.querySelector("#navbarResponsive"); // on re-route if nav open close it
      setTimeout(function () {
        if(navMenu.classList.contains("show")){
          navMenu.classList.remove("show");
        }
      },100);
    });

    // Sets the <title></title>
    title.setTitle('Mawdoo3 AI');

    // Sets the <meta> tag for the page
    meta.addTags([
      { name: 'author', content: 'Mawdoo3 AI' },
      { name: 'keywords', content: 'Mawdoo3 AI, Arabic Language API, Arabic Natural Language Processing, RESTful API, REST API, Ask Mawdoo3, Tashkeel, Arabic Diacritization, Arabic Search Engine, Named Entity Recognition, Topic Classification, Parts of Speech Analysis, Arabic Text Normalization, Sentiments Analysis, Similar Words, Word2Vec, Morphological Segmentation, Voice Recognition, Text To Speech, Deep Learning, Machine Learning, Salma' },
      { name: 'description', content: 'Mawdoo3 AI is a division of Mawdoo3 that specializes in providing Arabic language APIs as a service like Arabic Text-To-Speech, Named Entity' },
    ]);
  }

  ngOnInit(): void {
    let module = this;
    if(!localStorage.getItem('uid')) {
      localStorage.setItem('uid', this.guid());
    }

    document.addEventListener('play', function(e){
      var audios = document.getElementsByTagName('audio');
      for(var i = 0, len = audios.length; i < len;i++){
        if(audios[i] != e.target){
          audios[i].pause();
        }
      }
    }, true);

    document.addEventListener("click", function(e) {
      let target = $(e.target);
      if(!target.closest(".spell-checker-dropdown").length && $(".spell-checker-dropdown").is(":visible")) {
        $(".spell-checker-dropdown").hide();
      }
    });
  }

  private guid(): string {
    function s4(): string {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  }
}
