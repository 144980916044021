import { Injectable } from '@angular/core';
import { HttpClient } from '../../services/http-client.service';
import { AuthService } from '../../services/auth.service';
import { UtilService } from '../../services/util.service';
import { environment } from '../../../environments/environment';
import { AbstractNLPService } from '../AbstractNLPService'

@Injectable({ providedIn: 'root' })
export class SpellCheckerService extends AbstractNLPService {

  apiURL = 'https://api.mawdu.com/' + environment.newApiVersion + '/spell-checker';
  samplesFile = '/assets/samples/spell-check.json';
  method = 'post';
  resultText = '';
  title = 'spell checked statement';
  desc = 'Spell Checking API is a service by Mawdoo3 AI to correct spelling mistakes for an Arabic excerpt.';
  icon = '/assets/img/api-icons/search-engine.png';
  customText = true;

  constructor(protected http: HttpClient, protected auth: AuthService, protected util: UtilService) {
    super(http, auth, util)
  }

  present(response: any,query) {
    this.resultText = query;
    let suggestionsArray = [];
    if(response._body){
      suggestionsArray = JSON.parse(response._body).results.suggestions;
    }else{
      suggestionsArray = response.results.suggestions;
    }

    let htmlBody = "";
    let includedIndices = 0;

    $.each(query.split(/\s+/), function(index,word){
      if(includedIndices > 0){
        includedIndices--;
        return true;
      }
      
      if(suggestionsArray[index].length > 1){
        let suggestions = [];
        let suggestionsIndices = [];
        $.each(suggestionsArray[index], function(index,suggestion) {
          suggestions.push(suggestion[0]);
          suggestionsIndices.push(suggestion[1]);
        });

        $.each(suggestionsIndices, function(index,suggestionIndex){
          if(suggestionIndex > includedIndices)
          includedIndices = suggestionIndex;
        });

        if(includedIndices > 0){
          let jointWord = word + " ";
          let includedIndicesArray = new Array(includedIndices);

          $.each(includedIndicesArray, function(includedIndex,item){
            if((includedIndex + 1) ==  includedIndicesArray.length){
              jointWord = jointWord + (query.split(" "))[index + (includedIndex + 1)];
            }else{
              jointWord = jointWord + (query.split(" "))[index + (includedIndex + 1)] + " ";
            }
          });

          let parsedSuggestions = JSON.stringify(suggestions);
          htmlBody = htmlBody + "<span title='Right click to see suggested fixes' class='correctable' data-suggestions='"+ parsedSuggestions +"'><span class='correctable-inner'>"+ jointWord +"</span></span>" + " ";
        }else{
          let parsedSuggestions = JSON.stringify(suggestions);
          htmlBody = htmlBody + "<span title='Right click to see suggested fixes' class='correctable' data-suggestions='"+ parsedSuggestions +"'><span class='correctable-inner'>"+ word +"</span></span>" + " ";
        }
      }else{
        htmlBody = htmlBody + "<span>"+ word +"</span>" + " ";
      }
    });

    return { resultText: htmlBody }
  }

}
