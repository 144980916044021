import { Injectable } from '@angular/core';
import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Router } from '@angular/router';

import { AlertsService } from '../shared/alerts/alerts.service';
import { UtilService } from './util.service';

import { User } from '../models/user';

@Injectable()
export class AuthService {

  public currentUser;

  poolData = {
    UserPoolId: 'eu-west-1_xjZXgo31C',
    ClientId: '3l78u55rqii6fiks3mt6grrhok'
  }
  userPool: CognitoUserPool;
  cognitoUser: CognitoUser;

  constructor(
    private _alerts: AlertsService,
    private router: Router,
    private util: UtilService
  ){
    this.currentUser = this.getCurrentUser();
    this.userPool = new CognitoUserPool(this.poolData);
  }


  loggedIn(): boolean {
    return this.getCurrentUser() ? true : false;
  }


  getCurrentUser(): any {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(currentUser && currentUser.authToken) {
      return currentUser;
    }else {
      return null;
    }
  }


  isExpired(session): boolean {
    let exp = new Date(session.getAccessToken().getExpiration() * 1000);
    let now = new Date();
    return exp <= now;
  }


  getUserAttributes(attrs: string[] = [], currentUser = this.currentUser): Promise<any> {
    return new Promise((resolve, reject) => {
      var cognitoUser = new CognitoUser({ Username: currentUser.username, Pool: this.userPool });

      // NOTE: getSession must be called to authenticate user before calling getUserAttributes
      cognitoUser.getSession((err, session) => {
        if(err) {
          // add alert
          // this.logout();
          reject(err);
        }

        if(this.getCurrentUser() && this.isExpired(session)) {
          this.currentUser.authToken = null;
          localStorage.removeItem('currentUser');
          if(!this.cognitoUser) {
            return;
          }else {
            this.cognitoUser.signOut();
            this.cognitoUser = null;
          }
          this._alerts.addAlert({ type: 'warning', message: 'Your session has expired.' });
          this.router.navigate(['/signin']);
        }

        var self = this;
        cognitoUser.getUserAttributes(function(err, attributes) {
          if(err) {
            reject(err);
          }else {
            var userAttributes = {};
            for(var i = 0; i < attributes.length; i++) {
              if(attrs.length === 0) {
                userAttributes[self.util.toCamelCase(attributes[i].getName())] = attributes[i].getValue();
              }else if(attrs.includes(self.util.toCamelCase(attributes[i].getName()))) {
                userAttributes[self.util.toCamelCase(attributes[i].getName())] = attributes[i].getValue();
              }
            }
            resolve(userAttributes);
          }
        });
      });

    });
  }


  updateUserAttributes(attributeList: any[]): Promise<any> {
    return new Promise((resolve, reject) => {
      var cognitoUser = new CognitoUser({ Username: this.currentUser.username, Pool: this.userPool });

      // NOTE: getSession must be called to authenticate user before calling updateAttributes
      cognitoUser.getSession((err, session) => {
        if(err) {
          // add alert
          // this.logout();
          reject(err);
        }

        var attrList = [];
        for(let attr of attributeList) {
          attr.Name = this.util.toSnakeCase(attr.Name);
          attrList.push(new CognitoUserAttribute(attr));
        }

        cognitoUser.updateAttributes(attrList, (err, result) => {
          if(err) {
            reject(err);
          }else {
            resolve(result);
          }
        });

      });
    });
  }


  signup(user: User): Promise<any> {
    return new Promise((resolve, reject) => {
      var attributeList = [];
      var attributeEmail = new CognitoUserAttribute({ Name: 'email', Value: user.email });
      attributeList.push(attributeEmail);

      var attributePhoneNumber = new CognitoUserAttribute({ Name: 'phone_number', Value: user.phoneNumber });
      attributeList.push(attributePhoneNumber);

      var attributePromoCode = new CognitoUserAttribute({ Name: 'custom:promoCode', Value: user.promoCode });
      attributeList.push(attributePromoCode);

      this.userPool.signUp(user.username, user.password, attributeList, null, (err, result) => {
        if(err){
          reject(err);
          return;
        }
        resolve(result.user);
      })

    });
  }

  confirmRegistration(user: User): Promise<any> {
    return new Promise((resolve, reject) => {
      let cognitoUser = new CognitoUser({ Username: user.username, Pool: this.userPool });
      cognitoUser.confirmRegistration(user.confirmationCode, true, (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      });
    });
  }

  resendConfirmationCode(user: User): Promise<any> {
    return new Promise((resolve, reject) => {
      let cognitoUser = new CognitoUser({ Username: user.username, Pool: this.userPool });
      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
            reject(err);
            return;
        }
        resolve(result);
      });
    });
  }


  requestForgotPasswordCode(username: string): Promise<any> {
    return new Promise((resolve, reject) => {
      var cognitoUser = new CognitoUser({ Username: username, Pool: this.userPool });
      cognitoUser.forgotPassword({
        onSuccess: () => {
          resolve();
        },
        onFailure: (err) => {
          reject(err);
        }
      });
    });
  }


  confirmForgotPasswordCode(username: string, verificationCode: string, newPassword: string): Promise<any> {
    return new Promise((resolve, reject) => {
      var cognitoUser = new CognitoUser({ Username: username, Pool: this.userPool });
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: () => {
          resolve();
        },
        onFailure: (err) => {
          reject(err);
        }
      });
    });
  }


  login(user: User): Promise<any> {
    return new Promise((resolve, reject) => {
      var authDetails = new AuthenticationDetails({ Username: user.username, Password: user.password });
      var cognitoUser = new CognitoUser({ Username: user.username, Pool: this.userPool });

      var self = this;
      cognitoUser.authenticateUser(authDetails, {
        onSuccess(cognitoUserSession): void {
          self.getUserAttributes(['custom:apiKey'], { username: user.username })
            .then((attrs) => {
              user['apiKey'] = attrs['custom:apiKey'];
              user.username = user.username;

              self.saveUser(cognitoUserSession, user, cognitoUser);
              resolve(cognitoUserSession);
            })
            .catch((err) => {
              reject(err);
              return;
            });
        },
        onFailure(err): void {
          reject(err);
        }
      });
    });
  }


  saveUser(cognitoUserSession: CognitoUserSession, userModel: User, cognitoUser: CognitoUser): void {
    this.cognitoUser = cognitoUser;
    // this.username = userModel.username;
    let currentUser = {
        authToken: cognitoUserSession.getAccessToken().getJwtToken(),
        apiKey: userModel.apiKey,
        username: userModel.username
    };
    this.currentUser = currentUser;
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  }


  logout(): void {
    this.currentUser.authToken = null;
    localStorage.removeItem('currentUser');
    window.location.reload();
    if(!this.cognitoUser) {
      return;
    }else {
      this.cognitoUser.signOut();
      this.cognitoUser = null;
    }
  }

}
