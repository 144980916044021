import { Component, OnInit, Input, Output } from '@angular/core';
import { UtilService } from '../services/util.service';

@Component({
  selector: 'contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})

export class ContactUsComponent implements OnInit {

  @Input() inquiry: any = {};
  alerts: any[] = [];
  options: any = {};
  @Input() @Output() query: string;

  constructor(
    private util: UtilService
  ){}

  ngOnInit(): void {}

  queryChange(val) {
    this.query = `mailto:ai@mawdoo3.com?${this.util.toURLParams(this.options)}`;
  }

}
