import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Http } from '@angular/http';
import { AskService } from './ask.service';
import { AlertsService } from '../../shared/alerts/alerts.service';
import { TTSService } from '../tts/tts.service';
import { UtilService } from '../../services/util.service';
import { RecorderService } from '../../shared/recorder/recorder.service';
import { STTService } from '../stt/stt.service';
import { AuthService } from '../../services/auth.service';

import 'rxjs/add/operator/toPromise.js';


@Component({
  selector: 'ask-demo',
  templateUrl: './ask-demo.component.html',
  styleUrls: ['./ask-demo.component.css']
})

export class AskDemoComponent implements OnInit {
  showSpinner: boolean = false;
  inputType: string = 'text';
  includeWiki: boolean = false;
  askedByVoice: boolean = false;
  originalQuestion: string;
  loggedIn: boolean;

  @ViewChild('audio') audioPlayer;
  @Input() audio: Blob;
  question: string;
  audioId: string;
  result: any = {};

  readspeaker = {
    visible: false,
    blobUrl: null
  };

  samples: any[];
  audioSamples: any[] = [
    { id: 4, url: '/assets/samples/voice/ask/4.wav' },
    { id: 5, url: '/assets/samples/voice/ask/5.wav' }
  ];

  constructor(
    private askService: AskService,
    public _alerts: AlertsService,
    private ttsService: TTSService,
    private util: UtilService,
    public recorder: RecorderService,
    private sttService: STTService,
    private auth: AuthService,
    private http: Http
  ){
    this.loggedIn = this.auth.loggedIn();
    this.fetchSampleData();
  }


  fetchSampleData(): void {
    this.askService.fetchSampleData()
      .then((data => this.samples = data));
  }

  ngOnInit(): void {
    this.recorder.removeAudio();
  }

  setInputType(type): void {
    this.inputType = type;
  }

  ask(): void {
    var recorder = this.recorder.recordRTC;
    let source = this.includeWiki ? 'mawdoo3,wikipedia' : 'mawdoo3';

    if(this.inputType === 'audio') {
      if(!recorder) {
        this._alerts.addAlert({ type: 'danger', message: 'Something went wrong, please try again later.' });
        return;
      }
      this.resetPlayers();
      this.result = {};

      this.showSpinner = true;
      this.sttService.getText(recorder.getBlob())
        .then((rsp) => {
          this.question = rsp.text;
          this.audioId = rsp.id;
          this.askService.getAnswer(this.question, !this.loggedIn, source)
            .then((rsp) => {
              this.showSpinner = false;
              this.result = {};
              this.result.answer = rsp.results[0].answer;
              this.result.reference = rsp.results[0].reference;
              this.result.source = rsp.results[0].doc.source;
              this.result.title = rsp.results[0].title;

              this.originalQuestion = this.question;
              this.askedByVoice = true;
            })
            .catch((err) => {
              this.showSpinner = false;
              this._alerts.addAlert({ type: 'danger', message: 'Something went wrong, please try again later.' });
            });
        })
        .catch((err) => {
          this.showSpinner = false;
          if(err.status === 422)
            this._alerts.addAlert({ type: 'danger', message: 'I did not understand this, please try again.' });
          else
            this._alerts.addAlert({ type: 'danger', message: 'Something went wrong, please try again later.' });
        });
    } else {
      this.resetPlayers();
      this.result = {};

      this.showSpinner = true;
      this.askService.getAnswer(this.question, !this.loggedIn, source)
        .then((rsp) => {
          this.showSpinner = false;
          this.result = {};
          this.result.answer = rsp.results[0].answer;
          if(rsp.results[0].reference != 'knowledge_base'){
            this.result.reference = rsp.results[0].reference;
            this.result.source = rsp.results[0].doc.source;
            this.result.title = rsp.results[0].title;
          }

          this.originalQuestion = this.question;
          this.askedByVoice = false;
        })
        .catch((err) => {
          this.showSpinner = false;
          this._alerts.addAlert({ type: 'danger', message: 'Something went wrong, please try again later.' });
        });
    }

  }


  readAnswer(): void {
    this.showSpinner = true;

    this.resetPlayers();

    if(this.loggedIn){
      this.ttsService.run(this.result.answer)
        .then((blob) => {
          this.showSpinner = false;
          this.readspeaker.blobUrl = this.util.getSantizeUrl(URL.createObjectURL(blob));
          this.readspeaker.visible = true;
          // this.audioPlayer.load();
        })
        .catch((err) => {
          this.showSpinner = false;
          this._alerts.addAlert({ type: 'danger', message: 'Something went wrong with reading the answer.' });
        });
    }
    else {
      this.askService.getAudioAnswerSample(this.inputType, this.audioId, this.question)
      .then(rsp => {
        this.showSpinner = false;
        this.readspeaker.blobUrl = rsp;
        this.readspeaker.visible = true;
      });
    }
  }

  private resetPlayers(): void {
    this.readspeaker.visible = false;
    this.readspeaker.blobUrl = null;
  }


  setSample(question): void {
    this.question = question;
    this.ask();
  }

  setAudioSample(id): void {
    this.audioId = id
    this.showSpinner = true;
    this.askService.getAudioSample(id)
    .then(rsp => {
      this.showSpinner = false;
      this.result = {};
      this.result.answer = rsp.results[0].answer;
      this.result.reference = rsp.results[0].reference;
      this.result.source = rsp.results[0].doc.source;
      this.result.title = rsp.results[0].title;
    })
  }


  getVoice(text: string, service: string = 'mawdoo3', lang: string = 'ar-ar'): void {
    this.ttsService.run(text)
      .then((blob) => {
        this.showSpinner = false;
        this.readspeaker.blobUrl = this.util.getSantizeUrl(URL.createObjectURL(blob));
        this.readspeaker.visible = true;
        // this.audioPlayer.load();
      })
      .catch((err) => {
        this.showSpinner = false;
        this._alerts.addAlert({ type: 'danger', message: 'Something went wrong with reading the answer.' });
      });
  }


  contributionHandler(rsp): void {
    this.question = rsp.modifiedQuery;
    this.originalQuestion = this.question;
    this.setInputType('text');
    this.ask();
    this.recorder.removeAudio();
  }

}
