import { Component, OnInit, Input } from '@angular/core';
import { AlertsService } from '../shared/alerts/alerts.service';
import { User } from '../models/user';

@Component({
  selector: 'gharred-page',
  templateUrl: './gharred.component.html',
  styleUrls: ['./gharred.component.css']
})

export class GharredComponent implements OnInit {

  @Input() user: User = new User();

  showSpinner: boolean = false;
  alerts: any[] = [];

  constructor(
    private _alerts: AlertsService
  ){}

  ngOnInit(): void {
    this._alerts.alerts = [];
  }


}
