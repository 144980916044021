import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { HttpClient } from '../../services/http-client.service';
import { AuthService } from '../../services/auth.service';
import { UtilService } from '../../services/util.service';
import { environment } from '../../../environments/environment';
import { AbstractNLPService } from '../AbstractNLPService'

@Injectable({ providedIn: 'root' })
export class PosService extends AbstractNLPService {

  apiURL = 'https://api.mawdu.com/' + environment.newApiVersion + '/pos';
  samplesFile = '/assets/samples/pos.json'
  method = 'post'
  options = { headers: new Headers({ 'content-type': 'application/json' }) }
  resultList = []
  title = 'Parts of Speech'
  desc = 'Part of Speech Analysis API is a service by Mawdoo3 AI that classifies words and different language parts into their specific functions.'
  icon = '/assets/img/api-icons/part-of-speech-analysis.png'
  constructor(protected http: HttpClient, protected auth: AuthService, protected util: UtilService) {
    super(http, auth, util)
  }

  present(response: any) {
    let result;
    try {
      result = response.json().results
    } catch {
      result = response.results
    }
    var end_res = []
    for (var i = 0; i < result.length; ++i) {
      end_res.push(result[i]["word"] + ": " + result[i]["tag_ar"])
    }
    this.resultList = end_res
    return { resultList: this.resultList }
  }
}
