import { Injectable } from '@angular/core';
import { HttpClient } from '../../services/http-client.service';
import { AuthService } from '../../services/auth.service';
import { UtilService } from '../../services/util.service';
import { environment } from '../../../environments/environment';
import { AbstractNLPService } from '../AbstractNLPService'

@Injectable({ providedIn: 'root' })
export class TashkeelService extends AbstractNLPService {

  apiURL = 'https://api.mawdu.com/' + environment.newApiVersion + '/tashkeel';
  samplesFile = '/assets/samples/tashkeel.json'
  method = 'post'
  paramName ='text'
  resultText = ''
  title = 'Diacritized text'
  desc = 'Tashkeel API is a service by Mawdoo3 AI that provides diacritization for Arabic text. These charachters refer to the shape of the mouth when they are spoken.'
  icon = '/assets/img/api-icons/tashkeel.png'
  constructor(protected http: HttpClient
    , protected auth: AuthService,
    protected util: UtilService) {
    super(http, auth, util)
  }

  present(response: any) {
    try {
      this.resultText = response.json().results.text
    } catch{
      this.resultText = response.results.text
    }
    return { resultText: this.resultText }
  }
}
