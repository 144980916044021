import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { HttpClient } from '../../services/http-client.service';
import { AuthService } from '../../services/auth.service';
import { UtilService } from '../../services/util.service';
import { environment } from '../../../environments/environment';
import { AbstractNLPService } from '../AbstractNLPService'

@Injectable({ providedIn: 'root' })
export class SentimentsAnalysisService extends AbstractNLPService {

  apiURL = 'https://api.mawdu.com/' + environment.newApiVersion + '/sentiment';
  samplesFile = '/assets/samples/sentiments-analysis.json'
  method = 'post'
  options = { headers: new Headers({ 'content-type': 'application/json' }) }
  resultText = ''
  title = 'Sentiments'
  desc = 'Sentiment Analysis API is a service by Mawdoo3 AI that provides linguistic analysis to detect joy, fear, sadness, anger, analytical, confident and tentative tones found in text.'
  icon = '/assets/img/api-icons/sentiments-analysis.png'
  constructor(protected http: HttpClient,
    protected auth: AuthService,
    protected util: UtilService) {
    super(http, auth, util)
  }

  present(response: any) {
    let result
    try {
      result = response.json().result

    } catch{
      result = response.result
    }

    if (result !== undefined) {
      if (result['sentiment'] == 'positive') {
        this.resultText = "إيجابي " + ((parseFloat(result['confidence']) * 100.0).toFixed(2)) + "%";
      }
      else if (result['sentiment'] == 'negative') {
        this.resultText = "سلبي " + ((parseFloat(result['confidence']) * 100.0).toFixed(2)) + "%";
      }
    }
    return { resultText: this.resultText }
  }
}
