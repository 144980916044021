import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { HttpClient } from '../../services/http-client.service';
import { AuthService } from '../../services/auth.service';
import { UtilService } from '../../services/util.service';
import { environment } from '../../../environments/environment';
import { AbstractNLPService } from '../AbstractNLPService'

@Injectable({ providedIn: 'root' })
export class CoRefService extends AbstractNLPService {

  apiURL = 'https://api.mawdu.com/' + environment.newApiVersion + '/coreference-resolution/get-clusters';
  samplesFile = '/assets/samples/coref.json'
  method = 'post'
  options = { headers: new Headers({ 'content-type': 'application/json' }) }
  paramName = 'document'
  resultText = ''
  resultList = []
  customText = true
  customList = true
  title = 'Co-References'
  desc = 'Coreference Resolution API is a service by Mawdoo3 AI that is concerned with finding all expressions that refer to the same entity in a text.'
  icon = '/assets/img/api-icons/co-reference-resolution.png'
  colours = [
    "#e6194b", //Red
    "#3cb44b", //Green
    "#ffe119", //Yellow
    "#0082c8", //Blue
    "#f58231", //Orange
    "#911eb4", //Purple
    "#46f0f0", //Cyan
    "#f032e6", //Magenta
    "#d2f53c", //Lime
    "#fabebe", //Pink
    "#008080", //Teal
    "#e6beff", //Lavender
    "#aa6e28", //Brown
    "#fffac8", //Beige
    "#800000", //Maroon
    "#aaffc3", //Mint
    "#808000", //Olive
    "#ffd8b1", //Coral
    "#000080", //Navy
    "#808080", //Grey
  ]

  constructor(protected http: HttpClient,
    protected auth: AuthService,
    protected util: UtilService) {
    super(http, auth, util)
  }

  present(rawResponse, query) {
    let result = rawResponse["predicted_clusters"]
    if(!result)
      result = rawResponse.json().predicted_clusters
    if (!result)
      return
    let resultsArray
    var fr = query;
    var text_colours = []
    var resultsArrayBuffer = []
    for (var i = 0; i < result.length; ++i) {
      resultsArrayBuffer.push((i + 1) + " - ");
      for (var j = result[i].length - 1; j >= 0; --j) {
        var st = parseInt(result[i][j][0]);
        var ed = parseInt(result[i][j][1]) + 1;
        text_colours.push([]);
        text_colours[text_colours.length - 1].push(i);
        text_colours[text_colours.length - 1].push(st);
        text_colours[text_colours.length - 1].push(ed);
      }
      for (var j = 0; j < result[i].length; ++j) {
        var st = parseInt(result[i][j][0]);
        var ed = parseInt(result[i][j][1]) + 1;
        if (j > 0) {
          resultsArrayBuffer[i] += "، <span style=\"color:" + this.colours[i] + "\">" + fr.substring(st, ed) + "</span>";
        }
        else {
          resultsArrayBuffer[i] += "<span style=\"color:" + this.colours[i] + "\">" + fr.substring(st, ed) + "</span>";
        }
      }
    }
    text_colours.sort(function (a, b) {
      return b[1] - a[1];
    })

    var min_st = Number.MAX_SAFE_INTEGER;
    var min_ed = Number.MAX_SAFE_INTEGER;
    for (var i = 0; i < text_colours.length; ++i) {
      var colour = this.colours[text_colours[i][0]]
      st = text_colours[i][1]
      ed = text_colours[i][2]
      if (st < min_st && ed < min_ed) {
        min_st = st;
        min_ed = ed;
        fr = fr.substring(0, st) + "<span style=\"color:" + colour + "\">" + fr.substring(st, ed) + "</span>" + fr.substring(ed, fr.length);
      }
    }
    if (resultsArrayBuffer.length > 0) {
      resultsArray = resultsArrayBuffer;
    }
    else {
      resultsArray = null;
    }
    this.resultText = fr
    this.resultList = resultsArray
    return { resultText: this.resultText, resultList: this.resultList }
  }
}
