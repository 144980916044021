import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { HttpClient } from '../../services/http-client.service';
import { AuthService } from '../../services/auth.service';
import { UtilService } from '../../services/util.service';
import { environment } from '../../../environments/environment';
import { AbstractNLPService } from '../AbstractNLPService'

@Injectable({ providedIn: 'root' })
export class NormalizeService extends AbstractNLPService {

  apiURL = 'https://api.mawdu.com/' + environment.newApiVersion + '/filters';
  samplesFile = '/assets/samples/normalize.json'
  method = 'post'
  options = { headers: new Headers({ 'content-type': 'application/json' }) }
  resultText = ''
  title = 'Normalized Text'
  desc = 'Text Normalization API is a service by Mawdoo3 AI that unifies different forms of some characters and alphabet in the Arabic language.'
  icon = '/assets/img/api-icons/text-normalization.png'
  constructor(protected http: HttpClient, protected auth: AuthService, protected util: UtilService) {
    super(http, auth, util)
  }

  present(response: any) {
    try {
      this.resultText = response.json().result
    } catch  {
      this.resultText = response.result
    }
    return { resultText: this.resultText }
  }
}
