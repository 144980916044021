import { Injectable } from '@angular/core';
import { HttpClient } from '../../services/http-client.service';
import { AuthService } from '../../services/auth.service';
import { UtilService } from '../../services/util.service';
import { environment } from '../../../environments/environment';
import { AbstractNLPService } from '../AbstractNLPService'

@Injectable({ providedIn: 'root' })
export class TopicService extends AbstractNLPService {

  apiURL = 'https://api.mawdu.com/' + environment.newApiVersion + '/topics';
  samplesFile = '/assets/samples/topic.json'
  method = 'post'
  resultList = []
  title = 'Topics'
  desc = 'Topic Classification API is a service by Mawdoo3 AI that extracts the mentioned / covered topics in the text.'
  icon = '/assets/img/api-icons/topic-classification.png'
  constructor(protected http: HttpClient, protected auth: AuthService, protected util: UtilService) {
    super(http, auth, util)
  }

  present(response: any) {
    let end_results = []
    let results
    try {
      results = response.json().results
    } catch {

      results = response.results
    }

    if(results != null){
      for (var i = 0; i < results.length; ++i) {
        end_results.push(results[i]['topic'] + ' ' + ((parseFloat(results[i]['confidence']) * 100.0).toFixed(2)) + "%");
      }
    }
    this.resultList = end_results
    return { resultList: this.resultList }
  }

}
