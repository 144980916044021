import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// import components
import { LandingPageComponent } from './landing-page/landing-page.component';
import { SalmaComponent } from './salma/salma.component';
import { Nsurl2019Task8Component } from './nsurl-2019-task8/nsurl-2019-task8.component';
import { GharredComponent } from './gharred/gharred.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

import { ApisGridComponent } from './apis/apis-grid.component';
import { UserAccountComponent } from './users/account/account.component';
import { ApiDocComponent } from './api-doc/api-doc.component';
import { PricingComponent } from './pricing/pricing.component';
import { AppPrivacyPolicyComponent } from './app-privacy-policy/app-privacy-policy.component';
import { AllInOneComponent } from './apis/all-in-one/all-in-one.component';
import { SingleApiComponent } from './apis/single-api/single-api.component';
import { TTSSaudiDemoComponent } from './apis/tts-saudi/tts-saudi-demo.component';

// import services
import { AuthGuard } from './guards/auth.guard';
import { GuestGuard } from './guards/guest.guard';


const routes: Routes = [
  { path: '', component: LandingPageComponent },//, canActivate: [GuestGuard] 
  { path: 'home', component: LandingPageComponent },//, canActivate: [GuestGuard] 

  { path: 'salma', component: SalmaComponent },
  { path: 'nsurl-2019-task8', component: Nsurl2019Task8Component },
  { path: 'gharred', component: GharredComponent },
  // { path: 'pricing', component: PricingComponent },
  { path: 'about', component: AboutUsComponent },
  { path: 'contact', component: ContactUsComponent },
  { path: 'app-privacy-policy', component: AppPrivacyPolicyComponent },
  { path: 'mixed', component: AllInOneComponent },
  { path: 'apis', component: ApisGridComponent },
  { path: 'apis/tashkeel', component: SingleApiComponent, data:{service: "tashkeelService",service_title: "Tashkeel",title: "Diacritized text",service_desc: "Tashkeel API is a service by Mawdoo3 AI that provides diacritization for Arabic text. These charachters refer to the shape of the mouth when they are spoken."}},
  { path: 'apis/coref', component: SingleApiComponent, data:{service: "coRefService",service_title: "Co-Reference Resolution",title: "Co-References",service_desc: "Coreference Resolution API is a service by Mawdoo3 AI that is concerned with finding all expressions that refer to the same entity in a text."}},
  { path: 'apis/tts', component: SingleApiComponent, data:{service: "ttsService",service_title: "Text To Speech (TTS)",title: "Mawdoo3 Player",service_desc: "Text to Speech API is a service by Mawdoo3 AI that transforms written text into Salma AI's voice."}},
  { path: 'apis/stt', component: SingleApiComponent, data:{service: "STTService",service_title: "Speech To Text (STT)",title: "Text Output",service_desc: "Speech to Text API is a service by Mawdoo3 AI that transfers voice to text."}},
  { path: 'apis/dialect', component: SingleApiComponent, data:{service: "dialectService",service_title: "Dialect Detection",title: "Dialect",service_desc: "Dialect Detection API is a service by Mawdoo3 AI that detects different Arabic dialects."}},
  // { path: 'apis/search', component: SearchDemoComponent },
  // { path: 'apis/ask', component: AskDemoComponent },
  { path: 'apis/topic', component: SingleApiComponent, data:{service: "topicService",service_title: "Topic Classification",title: "Topics",service_desc: "Topic Classification API is a service by Mawdoo3 AI that extracts the mentioned / covered topics in the text."}},
  { path: 'apis/pos', component: SingleApiComponent, data:{service: "posService",service_title: "Part of Speech Analysis",title: "Parts of Speech",service_desc: "Part of Speech Analysis API is a service by Mawdoo3 AI that classifies words and different language parts into their specific functions."}},
  { path: 'apis/normalize', component: SingleApiComponent, data:{service: "normalizeService",service_title: "Text Normalization",title: "Normalized Text",service_desc: "Text Normalization API is a service by Mawdoo3 AI that unifies different forms of some characters and alphabet in the Arabic language."}},
  { path: 'apis/ner', component: SingleApiComponent, data:{service: "nerService",service_title: "Named Entity Recognition",title: "Entities",service_desc: "Named-entity recognition API is a serivice by Mawdoo3 AI that seeks to locate and classify named entity mentions in unstructured text into pre-defined categories such as the person names, organizations, locations and quantities."}},
  // { path: 'apis/nlp', component: NlpDemoComponent },
  { path: 'apis/morphological-segmentation', component: SingleApiComponent, data:{service: "segmentService",service_title: "Morphological Segmentation",title: "Morphological Segmented Text",service_desc: "Morphological Segmentation API is a service by Mawdoo3 AI that obtains the morphological segments from your text."}},
  // { path: 'apis/similar', component: SimilarDemoComponent },
  // { path: 'apis/correctness', component: CorrectnessDemoComponent },
  { path: 'apis/sentiments-analysis', component: SingleApiComponent, data:{service: "sentimentsAnalysisService",service_title: "Sentiment Analysis",title: "Sentiments",service_desc: "Sentiment Analysis API is a service by Mawdoo3 AI that provides linguistic analysis to detect joy, fear, sadness, anger, analytical, confident and tentative tones found in text."}},
  { path: 'apis/question-similarity', component: SingleApiComponent, data:{service: "QuestionSimilarityService",service_title: "Question Similarity",title: "Similarity",service_desc: "Question Similarty API is a service by Mawdoo3 AI to measure how similar two questions are."}},
  { path: 'apis/spell-checker', component: SingleApiComponent, data:{service: "spellCheckerService",service_title: "Spell Checking",title: "spell checked statement",service_desc: "Spell Checking API is a service by Mawdoo3 AI to correct spelling mistakes for an Arabic excerpt."}},
  { path: 'apis/tts-saudi', component: TTSSaudiDemoComponent },
  { path: 'docs/:doc', component: ApiDocComponent },
  { path: 'user/account', component: UserAccountComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
