import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertsService } from '../../shared/alerts/alerts.service';
import { User } from '../../models/user';
import { environment } from '../../../environments/environment';
import { HttpClient } from '../../services/http-client.service';

@Component({
  selector: 'main-navbar',
  templateUrl: './main-navbar.component.html',
  styleUrls: ['./main-navbar.component.css']
})

export class NavbarComponent {
  loggedIn;
  title: String = "Choose title";
  firstName: String = "";
  lastName: String = "";
  email: String = "";
  phoneNumber: String = "";
  company: String = "";
  message: String = "";
  showSpinner: boolean = false;
  userForm: string = 'signin';
  sendRequestText: string = 'Send Your Request';
  sendRequestDisabled: boolean = false;
  recaptchaChecked: boolean = false;
  recaptchaId: String = "";

  alerts: any[] = [];
  @Input() user: User = new User();

  @ViewChild('recaptcha') recaptchaElement: ElementRef;

  constructor(
    private _auth: AuthService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private alert: AlertsService,
    protected http: HttpClient
  ){
    this.loggedIn = this._auth.loggedIn.bind(_auth);
    this.alerts = this.alert.localAlerts;
  }

  ngOnInit() {
    this.addRecaptchaScript();
  }

  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptcha();
    }

    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
  }

  renderReCaptcha() {
    let module = this;
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : '6LdEdcUUAAAAACG7vJxNLHHyRIGmRQnC1DTiT0vj',
      'callback': (response) => {
        module.recaptchaId = response;
        module.recaptchaChecked = true;
      }
    });
  }

  logout(): void {
    this._auth.logout();
  }
  
  sendEmail() {
    let module = this;
    if(this.title == "Choose title" || this.firstName == "" || this.lastName == "" || this.email == "" || this.phoneNumber == "" || this.company == ""){
      this._snackBar.open("Please fill in all fields",null,{
        duration: 3000,
        verticalPosition: "top"
      });
      return false;
    }

    let email = String(this.email);
    if(!(/\S+@\S+\.\S+/.test(email))){
      this._snackBar.open("Please enter a valid email address",null,{
        duration: 3000,
        verticalPosition: "top"
      });
      return false;
    }

    let phone = String(this.phoneNumber);
    if(!(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(phone))){
      this._snackBar.open("Please enter a valid phone number",null,{
        duration: 3000,
        verticalPosition: "top"
      });
      return false;
    }

    if(!this.recaptchaChecked){
      this._snackBar.open("Please verify you are not a robot",null,{
        duration: 3000,
        verticalPosition: "top"
      });
      return false;
    }

    
    let url = 'https://api.mawdu.com/' + environment.newApiVersion + '/send-mail';

    let titleEnum;

    switch(this.title){
      case "Mr":
        titleEnum = 0;
        break;
      case "Mrs":
        titleEnum = 1;
        break;
      case "Miss":
        titleEnum = 2;
        break;
    }

    let data = {
      type: 0, 
      title: titleEnum,
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.email,
      phone_number: this.phoneNumber,
      company: this.company,
      recaptchaId: this.recaptchaId
    };

    if(this.message.trim() != ""){
      data['message'] = this.message; 
    }

    this.sendRequestText = "Sending your request...";
    this.sendRequestDisabled = true;

    this.http
      .post(url, data, null)
      .then(response => { 
        module._snackBar.open("Request sent successfully",null,{
          duration: 3000,
          verticalPosition: "top"
        });
  
        module.title = "Choose title";
        module.firstName = "";
        module.lastName = "";
        module.email = "";
        module.phoneNumber = "";
        module.company = "";
        module.message = "";
  
        let closeBtn = <HTMLElement> document.querySelector("#modal-contact .close");
        closeBtn.click();
  
        module.sendRequestText = "Send Your Request";
        module.sendRequestDisabled = false;

        window["grecaptcha"].reset();
       })
      .catch(function (error) {
        module._snackBar.open("Something went wrong! please try again",null,{
          duration: 3000,
          verticalPosition: "top"
        });
  
        module.sendRequestText = "Send Your Request";
        module.sendRequestDisabled = false;

        window["grecaptcha"].reset();
      });
  }

  setForm(val: string): void {
    this.alert.localAlerts = [];
    this.alerts = this.alert.localAlerts;
    this.userForm = val;
  }

  handleUserCreated(user): void {
    this.user = user;
    this.setForm('signin');
    this.alert.addAlert({ type: 'success', message: 'You have successfully signed up.' }, false)
  }

  resetSteps() {
    this.setForm('signin');
  }

}
