import { Component, OnInit, ViewChild } from '@angular/core';
import { RecorderService } from './recorder.service';
import { AlertsService } from '../alerts/alerts.service';

import * as RecordRTC from 'recordrtc';

@Component({
  selector: 'audio-record',
  templateUrl: './recorder.component.html',
  styleUrls: ['./recorder.component.css']
})

export class AudioRecordingComponent implements OnInit {

  @ViewChild('player') player: any;

  stream;
  recording: boolean = false;
  recordingTime: number = 0;
  timer: any;
  counter: any;
  alerts: any[] = [];

  constructor(
    private recorder: RecorderService,
    private _alerts: AlertsService
  ){ }

  ngOnInit(): void {
    if(this.recorder.hasAudio) {
      let player: HTMLAudioElement = this.player.nativeElement;
      let source: HTMLSourceElement = player.querySelector('source');

      let audioURL = this.recorder.getAudioBlobURL();
      source.src = audioURL;
      player.load();
    }
  }


  private resetPlayer(): void {
    this.recorder.hasAudio = false;
    this.recorder.recordRTC = null;
    let player: HTMLAudioElement = this.player.nativeElement;
    let source: HTMLSourceElement = player.querySelector('source');
    source.src = '';
    // player.load();
  }


  startRecording(): void {
    this.resetPlayer();
    let mediaConstraints = {
      audio: true,
      video: false
    };

    this.alerts = [];
    // getting audio stream
    try {
      navigator.mediaDevices
        .getUserMedia(mediaConstraints)
          .then((stream) => {
            let options = {
              type: 'audio',
              mimeType: 'audio/wav',
              recorderType: RecordRTC.StereoAudioRecorder,
              disableLogs: true,
              numberOfAudioChannels: 2
            };

            this.stream = stream;
            this.recorder.recordRTC = RecordRTC(stream, options);
            this.recorder.recordRTC.startRecording();
            this.recording = true;

            this.timer = setInterval(() => {
              this.recordingTime++;
            }, 1000);

            this.counter = setTimeout(() => {
              if (this.recording)
                clearInterval(this.timer);
                clearTimeout(this.counter);
                this.recordingTime = 0;
                this.stopRecording();
                this._alerts.addAlert({ type: 'warning', message: 'Maximum recording time is 30 seconds, the recording has stopped automatically' });
            }, 30000);

          }, (error) => {
            clearTimeout(this.counter);
            clearInterval(this.timer);
            this.timer = 0;
            // error callback
            // TODO add error
          });

    } catch (e) {
      this._alerts.addAlert({ type: 'danger', message: 'This browser does not support audio recording.' });
    }

  }


  stopRecording(): void {
    let player: HTMLAudioElement = this.player.nativeElement;
    let source: HTMLSourceElement = player.querySelector('source');

    clearTimeout(this.counter);
    clearInterval(this.timer);
    this.recordingTime = 0;

    let recordRTC = this.recorder.recordRTC;
    this.recording = false;
    this.recorder.hasAudio = true;
    this.recorder.recordRTC.stopRecording((audioURL) => {
      source.src = audioURL;
      player.load();
      // var audioBlob = this.recorder.recordRTC.getBlob();
    });
  }

  closeAlert(i:number) {
    this.alerts.splice(i, 1);
  }

}
