import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '../services/http-client.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'api-doc',
  template: `
    <iframe
        sandbox="allow-same-origin allow-scripts"
        [attr.srcDoc]="srcDocContent | keepHtml"
        style="width: 99vw; height: 100vh"></iframe>
  `
})

export class ApiDocComponent {
  srcDocContent: string = '';
  constructor(
    private route: ActivatedRoute,
    protected http: HttpClient,
    private auth: AuthService
  ) {
    if (this.auth.loggedIn()) {
      var apiKey = this.auth.currentUser.apiKey;
    }
    this.route.params.subscribe(params => {
        var content = this.http
          .get(`/assets/docs/${params['doc']}.html`)
          .then(rsp => {
            if (this.auth.loggedIn()) {
              this.srcDocContent = rsp._body.replace(/\[\[apiKey\]\]/g, apiKey)
                                            .replace(/YOUR API KEY/g, apiKey)
                                            .replace(/YOUR_API_KEY/g, apiKey)
                                            .replace('<h2 id="welcome-to-apidoc">API and SDK Documentation</h2>',
                                                     '<h4 id="your-api-key">Your API Key: <code>' + apiKey + '</code></h4>');
            }
            else {
              this.srcDocContent = rsp._body;
            }
        });
    });
  }
}
