import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';

import { HttpClient } from '../../services/http-client.service';
import { AuthService } from '../../services/auth.service';
import { UtilService } from '../../services/util.service';

import { TashkeelService } from '../tashkeel/tashkeel.service'
import { NerService } from '../ner/ner.service'
import { SentimentsAnalysisService } from '../sentiments-analysis/sentiments-analysis.service'
import { TopicService } from '../topic/topic.service'
import { TTSService } from '../tts/tts.service'
import { SpellCheckerService } from '../spell-checker/spell-checker.service'
import { NormalizeService } from '../normalize/normalize.service'
import { PosService } from '../pos/pos.service'
import { QuestionSimilarityService } from '../question-similarity/question-similarity.service'
import { CoRefService } from '../coref/coref.service'
import { DialectService } from '../dialect/dialect.service'
import { SegmentService } from '../morphological-segmentation/segment.service'
import { STTService } from '../stt/stt.service'

@Injectable()
export class AllInOneService {

  loggedin: boolean
  constructor(private http: HttpClient,
    private auth: AuthService,
    private util: UtilService,
    private tashkeelService: TashkeelService,
    private nerService: NerService,
    private sentimentsAnalysisService: SentimentsAnalysisService,
    private topicService: TopicService,
    private ttsService: TTSService,
    private spellCheckerService: SpellCheckerService,
    private normalizeService: NormalizeService,
    private posService: PosService,
    private coRefService: CoRefService,
    private dialectService: DialectService,
    private segmentService: SegmentService,
    private QuestionSimilarityService: QuestionSimilarityService,
    private STTService: STTService) {
    this.loggedin = this.auth.loggedIn()
  }

  fetch(serviceName) {
    return Reflect.get(this, serviceName).fetchSamples()
  }

  run(serviceName, query) {
    
    var params = query;
    if ( serviceName != 'normalizeService' && serviceName != 'QuestionSimilarityService' && serviceName != 'ttsService') {
      params = {}
      let paramName = Reflect.get(this, serviceName).paramName
      params[paramName] = query
    }

    return Reflect.get(this, serviceName).run(params, !this.loggedin)

  }

  present(serviceName, result, query) {
    return Reflect.get(this, serviceName).present(result, query)
  }
}

