import { Component, Input } from '@angular/core';
import { AlertsService } from '../../shared/alerts/alerts.service';

import { AuthService } from '../../services/auth.service';

import { User } from '../../models/user';

@Component({
  selector: 'user-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class UserAccountComponent {

  @Input() user: User = new User();
  alerts: any[] = [];

  constructor(
    private _alerts: AlertsService,
    private auth: AuthService
  ) {
    this.getUserData();
  }

  getUserData(): void {
    this.auth.getUserAttributes(['email', 'phoneNumber', 'apiKey'])
      .then((attrs) => {
        this.user = this.user.fromJSON(attrs);
        this.user.username = this.auth.currentUser.username;
        this.user.apiKey = this.auth.currentUser.apiKey;
      })
      .catch((err) => {
        this._alerts.addAlert({ type: 'danger', message: 'Something went wrong, please come back later.' })
      });
  }

  updateUser(): void {
    var attributeList = [
      { Name: 'email', Value: this.user.email },
      { Name: 'phoneNumber', Value: this.user.phoneNumber }
    ];

    this.auth.updateUserAttributes(attributeList)
      .then((result) => {
        this._alerts.addAlert({ type: 'success', message: 'You have successfully updated your account.' })
      })
      .catch((err) => {
        if(err.statusCode === 400)
          this._alerts.addAlert({ type: 'danger', message: err.message })
        else {
          this._alerts.addAlert({ type: 'danger', message: 'Something went wrong, please try again later.' })
        }
      });
  }

}
