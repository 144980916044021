import { Injectable } from '@angular/core';
import { Response, RequestOptions, Headers, ResponseContentType } from '@angular/http';

import { HttpClient } from '../../services/http-client.service';
import { AuthService } from '../../services/auth.service';
import { UtilService } from '../../services/util.service';
import { RecorderService } from '../../shared/recorder/recorder.service';
import { environment } from '../../../environments/environment';


@Injectable()
export class AskService {

  private apiUrl = 'https://api.mawdu.com/text/' + environment.oldApiVersion;
  private options = {
    headers: new Headers()
  };

  constructor(
    protected http: HttpClient,
    protected auth: AuthService,
    private util: UtilService,
    private recorder: RecorderService
  ){ }

  fetchSampleData(): Promise<any> {
    return this.http
      .get(`/assets/samples/ask.json`)
      .then(rsp => {
        return rsp.json();
      });
  }

  getAnswer(question: any, sample: boolean = false, source: string = 'mawdoo3,wikipedia', limit: number = 1, format: string = 'json'): Promise<any> {
    if (sample) {
      return this.fetchSampleData()
        .then(samples => {
          return samples.find(sampleObj => sampleObj.query === question).result;
        })
    }

    var params = { question: question, source: source, limit: limit, format: format, knowledgebase: 0 };
    return this.http.get(`${this.apiUrl}/ask/?${this.util.toURLParams(params)}`, this.options)
      .then(rsp => rsp.json())
      .catch(err => err);
  }

  getAudioSample(id: string) {
    return this.http
      .get(`/assets/samples/ask.json`)
      .then(rsp => {
        let results = rsp.json();
        return results.find(x => x.id == id).result;
      });
  }

  getAudioAnswerSample(inputType: string, audioId: string, question: string) {
    return this.http
      .get(`/assets/samples/tts.json`)
      .then(rsp => {
        let results = rsp.json();
        let resID = '';

        if(inputType === 'audio') {
          resID = audioId;
        }
        else {
          resID = results.find(x => x.question == question).id;
        }
        return this.util.getSantizeUrl('/assets/samples/voice/tts/' + resID + '.wav')
      });
  }
}
