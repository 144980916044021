import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { AuthService } from '../services/auth.service';

import 'rxjs/add/operator/toPromise.js';

const DEFAULT_OPTIONS = { headers: new Headers() };


@Injectable()
export class HttpClient {

  constructor(
    private http: Http,
    private auth: AuthService
  ) {}

  createApiKeyHeader(options: any, apiKey: any) {
    if (this.auth.loggedIn() && options) {
      options.headers.set('x-api-key', apiKey);
      options.headers.set('Authorization', `Bearer ${this.auth.currentUser.authToken}`);
    }
  }

  get(url, options = DEFAULT_OPTIONS) {
    var apiKey = ''
    if(this.auth.currentUser && this.auth.currentUser.apiKey) {
      apiKey = this.auth.currentUser.apiKey
    }
    if(url.includes('https://api.mawdu.com/voice/dev')) {
      apiKey = '9jjxMm2PFu6HPnUworXX5S9EY0yTG9o9L7zPGAt9'
    }
    this.createApiKeyHeader(options, apiKey);
    return this.http.get(url, options)
      .toPromise()
      .then(rsp => Promise.resolve(rsp))
      .catch(this.handleError);
  }

  post(url, data, options = DEFAULT_OPTIONS) {
    var apiKey = ''
    if(this.auth.currentUser && this.auth.currentUser.apiKey) {
      apiKey = this.auth.currentUser.apiKey
    }
    if(url.includes('https://api.mawdu.com/voice/dev')) {
      apiKey = '9jjxMm2PFu6HPnUworXX5S9EY0yTG9o9L7zPGAt9'
    }
    this.createApiKeyHeader(options, apiKey);
    return this.http.post(url, data, options)
      .toPromise()
      .then(rsp => Promise.resolve(rsp))
      .catch(this.handleError);
  }

  handleError(error: any): Promise<any> {
    if (!error) {
      return Promise.reject({ message: 'Something went wrong, please try again later.' });
    }

    // handle session expiration
    if (error.statusCode == 403) {
      this.auth.logout();
    }

    // handle limit exceeded here!
    if (error.statusCode == 429) {
      if (error.statusText.includes('Throttled'))
        return Promise.reject({ ...error, message: 'Exceeded max requests per second (please try again later).' });
      else if(error.statusText.includes('Quota Exceeded'))
        return Promise.reject({ ...error, message: 'Monthly quota exceeded, please contact your account manager.' });
      else
        return Promise.reject(error);
    }

    return Promise.reject(error);
  }

}
