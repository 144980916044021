import { Component, OnInit, Input } from '@angular/core';
import { Http } from '@angular/http';

import { SimilarService } from './similar.service';
import { AlertsService } from '../../shared/alerts/alerts.service';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'similar-demo',
  templateUrl: './similar-demo.component.html',
  styleUrls: ['./similar-demo.component.css']
})

export class SimilarDemoComponent implements OnInit {
  showSpinner: boolean = false;

  @Input() text: string;
  loggedIn: boolean;
  samples: string[];
  results: string[];

  constructor(
    private similarService: SimilarService,
    private alerts: AlertsService,
    protected auth: AuthService
  ){
    this.loggedIn = this.auth.loggedIn();
    this.fetchSampleData();
  }

  ngOnInit(): void {}

  fetchSampleData(): void {
    this.similarService.fetchSampleData()
      .then((data => this.samples = data));
  }

  runSimilar(): void {
    this.showSpinner = true;

    this.similarService.runSimilar(this.text, !this.loggedIn)
      .then(result => {
        this.showSpinner = false;
        let res = [];
        for (var i=0; i < result.length; i++){
          res.push(result[i][0])
        }
        this.results = res
      })
      .catch(err => {
        this.showSpinner = false;
        if (err.status == 404){
          let res = [];
          res.push('لا يوجد نتائج')
          this.results = res
        }
        else {
          this.alerts.addAlert({ type: 'danger', message: err });
        }
      });
  }

  setSample(query): void {
    this.text = query;
    this.runSimilar();
  }

}
