import { Component, OnInit, Input } from '@angular/core';
import { Http } from '@angular/http';

import { CorrectnessService } from './correctness.service';
import { AlertsService } from '../../shared/alerts/alerts.service';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'correctness-demo',
  templateUrl: './correctness-demo.component.html',
  styleUrls: ['./correctness-demo.component.css']
})

export class CorrectnessDemoComponent implements OnInit {
  showSpinner: boolean = false;

  @Input() text: string;
  result: string = '';
  loggedIn: boolean;
  samples: string[];

  constructor(
    private correctnessService: CorrectnessService,
    private alerts: AlertsService,
    protected auth: AuthService
  ){
    this.loggedIn = this.auth.loggedIn();
    this.fetchSampleData();
  }

  ngOnInit(): void {}

  fetchSampleData(): void {
    this.correctnessService.fetchSampleData()
      .then((data => this.samples = data));
  }

  runCorrectness(): void {
    this.result = '';
    this.showSpinner = true;

    this.correctnessService.runCorrectness(this.text, !this.loggedIn)
      .then(result => {
        this.showSpinner = false;
        this.result = result;
      })
      .catch(err => {
        this.showSpinner = false;
         this.alerts.addAlert({ type: 'danger', message: err });
      });
  }

  setSample(query): void {
    this.text = query;
    this.runCorrectness();
  }

}
