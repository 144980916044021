import { Component, OnInit, Input } from '@angular/core';
import { Http } from '@angular/http';
import { SearchService } from './search.service';
import { AlertsService } from '../../shared/alerts/alerts.service';
import { RecorderService } from '../../shared/recorder/recorder.service';
import { STTService } from '../stt/stt.service';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'search-demo',
  templateUrl: './search-demo.component.html',
  styleUrls: ['./search-demo.component.css']
})

export class SearchDemoComponent implements OnInit {
  showSpinner: boolean = false;
  inputType: string = 'text';
  audioId: string;
  searchedByVoice: boolean = false;

  @Input() audio: Blob;

  query: string;
  includeWiki: boolean = false;
  results: string[] = [];
  originalQuery: string;
  autoCorrectedQuery: string;
  loggedIn: boolean;
  samples: any[];
  audioSamples: any[] = [
    { id: 1, url: '/assets/samples/voice/search/1.wav' },
    { id: 5, url: '/assets/samples/voice/search/5.wav' }
  ];

  readspeaker = {
    visible: false,
    blobUrl: null
  };

  constructor(
    private searchService: SearchService,
    private _alerts: AlertsService,
    public recorder: RecorderService,
    private sttService: STTService,
    private auth: AuthService,
    private http: Http
  ){
    this.loggedIn = this.auth.loggedIn();
    this.fetchSampleData();
  }

  ngOnInit(): void {
    this.recorder.removeAudio();
  }

  fetchSampleData(): void {
    this.searchService.fetchSampleData()
      .then((data => this.samples = data));
  }

  setInputType(type): void {
    this.inputType = type;
  }

  private resetPlayers(): void {
    this.readspeaker.visible = false;
    this.readspeaker.blobUrl = null;
  }

  search(): void {
    this.results = [];
    this.showSpinner = true;
    let source = this.includeWiki ? 'mawdoo3,wikipedia' : 'mawdoo3';

    if(this.inputType === 'audio') {
      var recorder = this.recorder.recordRTC;
      this.resetPlayers();

      this.sttService.getText(recorder.getBlob())
        .then((rsp) => {
          this.query = rsp.text;
          this.audioId = rsp.id;
          this.searchService.search(this.query, !this.loggedIn, source)
            .then((rsp) => {
              this.showSpinner = false;
              this.autoCorrectedQuery = rsp.autoCorrectedQuery;
              this.originalQuery = this.query;
              this.results = rsp.results;

              this.searchedByVoice = true;
            })
            .catch((err) => {
              this.showSpinner = false;
              this._alerts.addAlert({ type: 'danger', message: err });
            });
        })
        .catch((err) => {
          this.showSpinner = false;
          if(err.status === 422)
            this._alerts.addAlert({ type: 'danger', message: 'I did not understand this, please try again.' });
          else
            this._alerts.addAlert({ type: 'danger', message: 'Something went wrong, please try again later.' });
        });
    } else {
      this.searchService.search(this.query, !this.loggedIn, source)
        .then((rsp) => {
          this.showSpinner = false;
          this.autoCorrectedQuery = rsp.autoCorrectedQuery;
          this.originalQuery = this.query;
          this.results = rsp.results;

          this.searchedByVoice = false;
        })
        .catch((err) => {
          this.showSpinner = false;
          this._alerts.addAlert({ type: 'danger', message: err });
        });
    }
  }

  setSample(query): void {
    this.query = query;
    this.search();
  }

  setAudioSample(id): void {
    this.showSpinner = true;
    this.http
      .get(`/assets/samples/search.json`)
      .toPromise()
      .then(rsp => {
        let results = rsp.json();
        let res = results.find(x => x.id == id).result;
        this.showSpinner = false;
        this.results = res.results;
        this.searchedByVoice = true;
      });
  }

  correctQuery(): void {
    this.query = this.autoCorrectedQuery;
    this.search();
  }

  isQueryMisspelled(): boolean {
    return (this.originalQuery != this.autoCorrectedQuery) && this.results.length > 0;
  }


  contributionHandler(rsp): void {
    this.query = rsp.modifiedQuery;
    this.originalQuery = this.query;
    this.setInputType('text');
    this.search();
    this.recorder.removeAudio();
  }

}
