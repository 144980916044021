import { Injectable } from '@angular/core';
import { Headers, ResponseContentType } from '@angular/http';
import { HttpClient } from '../../services/http-client.service';
import { AuthService } from '../../services/auth.service';
import { UtilService } from '../../services/util.service';
import { environment } from '../../../environments/environment';
import { AbstractNLPService } from '../AbstractNLPService'
import { SafeUrl } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class TTSServiceSaudi extends AbstractNLPService {

  apiURL = 'https://api.mawdu.com/' + environment.newApiVersion + '/tts/saudi';
  samplesFile = '/assets/samples/tts-saudi.json'
  method = 'get'
  options = { headers: new Headers(), responseType: ResponseContentType.Blob }
  loggedIn: boolean;
  resultBlob: Promise<SafeUrl>;
  title = 'Mawdoo3 Player'
  isStreamable: Boolean;

  constructor(protected http: HttpClient,
    protected auth: AuthService,
    protected util: UtilService) {
    super(http, auth, util)
    this.loggedIn = this.auth.loggedIn();
    // streaming is not supported in Safari
    this.isStreamable = !is.safari();
  }


  run(text: string, isSample: boolean = false) {

    let params: any;
    if (isSample) {
      return this.fetchSamples()
        .then(samples => {
          params = text;
          return samples.find(sampleObj => sampleObj.query === params).result;
        })
    }

    params = { text: text, streaming: this.isStreamable, encoding: 'mp3' };
    return this.http
      .get(`${this.apiURL}?${this.util.toURLParams(params)}`, this.options)
      .then(response => { return response })
      .catch((err) => Promise.reject(err));


  }

  present(rsp: any, query) {
    this.resultBlob = null

    if (this.loggedIn) {
      this.resultBlob = new Promise<SafeUrl>((resolve, reject) => {
        resolve(this.util.getSantizeUrl(URL.createObjectURL(rsp.blob())))
      })
    } else {
      this.resultBlob = this.http.get(`/assets/samples/tts-saudi.json`)
        .then(rsp => {
          let results = rsp.json();
          let resID = ''
          resID = results.find(x => x.query === query).id;
          return this.util.getSantizeUrl('/assets/samples/voice/tts-saudi/' + resID + '.wav')
        })

    }
    return { resultBlob: this.resultBlob }
  }


}
