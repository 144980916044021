import { Injectable } from '@angular/core';
// import { Http, Headers } from '@angular/http';

import * as RecordRTC from 'recordrtc';
// import 'rxjs/add/operator/toPromise';

@Injectable()
export class RecorderService {

  public recordRTC: RecordRTC;
  public sampleRate = 44100;
  public hasAudio: boolean = false;

  constructor(){ }


  // hasAudio(): boolean {
  //   if(this.recordRTC && this.recordRTC.getBlob())
  //     return true;
  //   else
  //     return false;
  // }


  getAudioBinary(): any {
    if(!this.hasAudio)
      return null;
    this.recordRTC.getDataURL(function(dataURL) {
      return dataURL;
    });
  }


  getAudioBlob(): Blob {
    if(!this.hasAudio)
      return null;
    return this.recordRTC.getBlob();

  }


  downloadAudio(): void {
    if(!this.hasAudio)
      return;
    this.recordRTC.save('audio.wav');
  }


  getAudioBlobURL(): string {
    if(!this.hasAudio)
      return null;
    return this.recordRTC.toURL();
  }

  removeAudio(): void {
    this.recordRTC = null;
    this.hasAudio = false;
  }


}
