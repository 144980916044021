import { Injectable } from '@angular/core';


@Injectable()
export class AlertsService {

  public alerts = [
    // { type: 'danger', message: 'Fahmi Al0Najar' }
  ]
  public localAlerts = []

  constructor(){ }

  addAlert(alert: any, global = true, timeout: number = 5000): void {
    var alerts = this.alerts;
    if(!global)
      alerts = this.localAlerts;

    alerts.push(alert);
    while (alerts.length > 1){
      alerts.splice(0, 1);
    }
  }

}
