import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';

import { HttpClient } from '../services/http-client.service';
import { AuthService } from '../services/auth.service';
import { UtilService } from '../services/util.service';
/**
 * Abstract class for all NLP services
 * @methods
 * fetchSamples: fetches samples data from the service json file
 * 
 * run: send a request to the service API
 * 
 * present: parse the response from the service API and prepare it for the presenter
 */
@Injectable({ providedIn: 'root' })
export abstract class AbstractNLPService {

    protected samplesFile: string;
    protected apiURL: string;
    protected options = { headers: new Headers() };
    protected method: string;
    paramName: string = 'text';
    constructor(protected http: HttpClient, protected auth: AuthService, protected util: UtilService) { }


    // fetch samples from json file
    fetchSamples(): Promise<any> {
        return this.http.get(this.samplesFile).then(rsp => { return rsp.json() })
    }

    // send query to api
    run(params: any, isSample: boolean = false) {
        if (isSample) {
            return this.fetchSamples()
                .then(samples => {
                    params = params[this.paramName]
                    return samples.find(sampleObj => sampleObj.query === params).result;
                })
        }

        if (this.method === 'post') {
            return this.http
                .post(this.apiURL, params, this.options)
                .then(response => { return response })
                .catch((err) => Promise.reject(err));
        } else if (this.method === 'get') {
            return this.http
                .get(`${this.apiURL}?${this.util.toURLParams(params)}`, this.options)
                .then(response => { return response })
                .catch((err) => Promise.reject(err));
        }

    }

    // parse the presention from API or Sample  
    present(result: any, query: any): any {
        try {
            return result.json()
        } catch{
            return result
        }
    }
}


