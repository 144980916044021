import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { HttpClient } from '../../services/http-client.service';
import { AuthService } from '../../services/auth.service';
import { UtilService } from '../../services/util.service';
import { environment } from '../../../environments/environment';
import { AbstractNLPService } from '../AbstractNLPService'

@Injectable({ providedIn: 'root' })
export class QuestionSimilarityService extends AbstractNLPService {

  apiURL = 'https://api.mawdu.com/' + environment.newApiVersion + '/q2q-semantics';
  samplesFile = '/assets/samples/question-similarity.json'
  method = 'post'
  options = { headers: new Headers({ 'content-type': 'application/json' }) }
  resultText = ''
  title = 'Question Similarity'
  desc = 'Question Similarty API is a service by Mawdoo3 AI to measure how similar two questions are.'
  icon = '/assets/img/api-icons/sentence-similarity.png'


  constructor(protected http: HttpClient,
    protected auth: AuthService,
    protected util: UtilService) {
    super(http, auth, util)
  }

  run(params: any, isSample: boolean = false) {
    if (isSample) {
      return this.fetchSamples()
        .then(samples => {
          return samples.find(sampleObj => sampleObj.query.search(params.q1) >= 0).result;
        })
    }

    return this.http
      .post(this.apiURL, params, this.options)
      .then(response => { return response })
      .catch((err) => Promise.reject(err));
  }

  present(respose: any) {
    let results
    try {
      results = respose.json().results
    } catch {
      results = respose.results
    }
    if (results)
      this.resultText = (results["similarity"] == 0 ? "غير متشابه" : "متشابه") + "  " + (100 * results["confidence"]).toFixed(1) + "%";
    return { resultText: this.resultText }
  }

}
